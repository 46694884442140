import {
  ADD_USER,
  DELETE_USER,
  GET_USERS,
  SET_LOADING_SELECT,
  SET_USER_FORM_DATA,
  UPDATE_USER,
} from '../actions/types';

const initialState = {
  loadingSelect: false,
  users: [],
  addUserData: {
    name: '',
    username: '',
    role: '',
  },
  deleteUser: false,
  updateUser: false,
  addUser: [],
};

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case SET_LOADING_SELECT:
      return {
        ...state,
        loadingSelect: payload,
      };
    case SET_USER_FORM_DATA:
      const addNewData = { ...state.addUserData };
      addNewData[payload.field] = payload.value;
      return {
        ...state,
        addUserData: addNewData,
      };
    case GET_USERS:
      return {
        ...state,
        users: payload,
      };
    case DELETE_USER:
      return {
        ...state,
        deleteUser: payload,
      };
    case UPDATE_USER:
      return {
        ...state,
        updateUser: payload,
      };
    case ADD_USER:
      return {
        ...state,
        addUser: payload,
        loading: false,
      };
    default:
      return state;
  }
}
