import React, { Fragment, useEffect, useState, useCallback } from 'react';
import { connect } from 'react-redux';
import Lozenge from '@atlaskit/lozenge';
import Button from '@atlaskit/button';
import DropdownMenu, {
  DropdownItem,
  DropdownItemGroup,
} from '@atlaskit/dropdown-menu';
import { FaPlusCircle } from 'react-icons/fa';
import 'react-datepicker/dist/react-datepicker.css';
import MaintenanceForm from '../ticket/MaintenanceForm';
import { showMarkedValues, clearFormData } from '../../actions/issue';
import { setViewFormData } from '../../actions/servicedesk';
import * as manageActions from '../../actions/manage';
import {
  getQueueIssues,
  getOrganizations,
  checkSession,
  logoutJira,
} from '../../actions/jsd';
import IncidentForm from '../ticket/IncidentForm';
import ViewDetails from './ViewDetails';
import { ticketStatusFormatter } from '../../utils';
import styled from 'styled-components';
import DynamicTableStateless from '@atlaskit/dynamic-table';
import { withCookies } from 'react-cookie';

const Wrapper = styled.div`
  min-width: 600px;
`;

const Dashboard = ({
  history,
  cookies,
  showMarkedValues,
  clearFormData,
  setViewFormData,
  getRecipients,
  getQueueIssues,
  getProviders,
  manage: { info, jsdMappings },
  jsd: { loading, queueSize, isAuthenticated },
  servicedesk: { requests, request },
  getJsdMappings,
  checkSession,
  logoutJira,
}) => {
  useEffect(() => {
    getJsdMappings();
    checkSession();
  }, []);

  useEffect(() => {
    if (queueSize === 0) {
      isAuthenticated && getProviders();
      isAuthenticated && getRecipients();
      isAuthenticated && jsdMappings && getQueueIssues(jsdMappings);
    }
  }, [isAuthenticated, info, queueSize, getQueueIssues, getRecipients]);

  useEffect(() => {
    request && getQueueIssues(jsdMappings);
  }, [request, jsdMappings]);

  const [events, setEvents] = useState(['click', 'load', 'scroll']);

  let logout = useCallback(() => {
    const sessionId = cookies.get('connect.sid');
    !sessionId && logoutJira();
  }, []);

  // Life cycle hook
  useEffect(() => {
    events.forEach((event) => {
      window.addEventListener(event, logout);
    });
  }, [logout]);

  const { jsdRequestType: jsdRequestTypes, requestType } = jsdMappings || {};

  const [modal, setModal] = useState(false);
  const [formType, setFormType] = useState();

  const toggleModal = (type) => {
    setFormType(type);
    showMarkedValues(false);
    setModal(!modal);
    if (modal === false) {
      clearFormData();
    }
  };

  const [modalView, setModalView] = useState(false);

  const toggleModalView = () => {
    setModalView(!modalView);
  };

  const [modalIncident, setModalIncident] = useState(false);

  const toggleModalIncident = () => {
    setModalIncident(!modalIncident);
    showMarkedValues(false);
    if (modalIncident === false) {
      clearFormData();
    }
  };

  const [modalViewIncident, setModalViewIncident] = useState(false);

  const toggleModalViewIncident = () => {
    setModalViewIncident(!modalViewIncident);
  };

  const requestTypeFormatter = (cell) => {
    switch (cell) {
      case jsdRequestTypes.maintenance.issueTypeId: {
        return <Lozenge isBold>{jsdRequestTypes.maintenance.name}</Lozenge>;
      }
      case jsdRequestTypes.incident.issueTypeId: {
        return (
          <Lozenge appearance="moved" isBold>
            {jsdRequestTypes.incident.name}
          </Lozenge>
        );
      }

      default: {
        return '-';
      }
    }
  };

  const issueKeyFormatter = (cell) => {
    return (
      <a
        href="#foo"
        className="anchor-action"
        onClick={(event) => {
          event.preventDefault();
          const issue =
            (requests.length &&
              requests.find(({ issueKey }) => {
                return cell === issueKey;
              })) ||
            {};

          const {
            createdDate,
            requestTypeId,
            issueKey,
            summary,
            type,
            provider,
            productRef,
            providerTicketNo,
            startDate,
            endDate,
            backupStartDate,
            backupEndDate,
            organization,
            jsdPortal,
            impact,
            purpose,
            location,
            outage,
            body,
            priority,
            incidentType,
            impactedServices,
            organizations,
          } = issue;

          if (requestTypeId === jsdRequestTypes.incident.id) {
            toggleModalViewIncident();
          } else {
            toggleModalView();
          }

          setViewFormData({
            createdDate,
            issueKey,
            requestTypeId,
            summary,
            type,
            provider,
            productRef,
            providerTicketNo,
            startDate,
            endDate,
            backupStartDate,
            backupEndDate,
            organization,
            jsdPortal,
            impact,
            purpose,
            location,
            outage,
            body,
            priority,
            incidentType,
            impactedServices,
            organizations,
          });
        }}>
        {cell}
      </a>
    );
  };

  const createHead = (withWidth) => {
    return {
      cells: [
        {
          key: 'issueKey',
          content: 'JIRA Ticket',
          shouldTruncate: true,
          isSortable: true,
          width: withWidth ? 10 : undefined,
        },
        {
          key: 'summary',
          content: 'Description',
          width: withWidth ? 30 : undefined,
        },
        {
          key: 'startDate',
          content: 'Start',
          width: withWidth ? 18 : undefined,
        },
        {
          key: 'endDate',
          content: 'End',
          width: withWidth ? 18 : undefined,
        },
        {
          key: 'requestTypeId',
          content: 'Type',
          shouldTruncate: true,
          width: withWidth ? 10 : undefined,
        },
        {
          key: 'status',
          content: 'Status',
          shouldTruncate: true,
        },
      ],
    };
  };
  const head = createHead(true);

  const rows = requests.map((request) => ({
    key: `row-${request.issueId}`,
    cells: [
      {
        key: request.issueKey,
        content: issueKeyFormatter(request.issueKey),
      },
      {
        content: request.summary,
      },
      {
        content: request.startDate,
      },
      {
        content: request.endDate,
      },
      {
        content: jsdRequestTypes && requestTypeFormatter(request.requestTypeId),
      },
      {
        content: ticketStatusFormatter(request.status),
      },
    ],
  }));

  return (
    <Fragment>
      <div className="container-fluid mt-3">
        <div className="row">
          <div className="col-md-8"></div>
          <div className="col-md-4">
            <DropdownMenu
              shouldFitContainer
              trigger={
                <Button shouldFitContainer appearance="primary">
                  MANAGE ANNOUNCEMENT
                </Button>
              }>
              <DropdownItemGroup>
                <DropdownItem
                  className="btn-hover"
                  onClick={() => {
                    toggleModal(requestType.planned.name);
                  }}>
                  <span>
                    <FaPlusCircle className="mr-2" />
                    CREATE A PLANNED MAINTENANCE ANNOUNCEMENT
                  </span>
                </DropdownItem>
                <DropdownItem
                  className="btn-hover"
                  onClick={() => {
                    toggleModal(requestType.emergency.name);
                  }}>
                  <span>
                    <FaPlusCircle className="mr-2" />
                    CREATE AN EMERGENCY MAINTENANCE ANNOUNCEMENT
                  </span>
                </DropdownItem>
                <DropdownItem
                  className="btn-hover"
                  onClick={() => {
                    toggleModalIncident();
                  }}>
                  <span>
                    <FaPlusCircle className="mr-2" />
                    CREATE AN INCIDENT ANNOUNCEMENT
                  </span>
                </DropdownItem>
              </DropdownItemGroup>
            </DropdownMenu>
          </div>
        </div>
        <div className="mt-2">
          <Wrapper>
            <DynamicTableStateless
              head={head}
              rows={rows}
              isLoading={loading}
              rowsPerPage={10}
              defaultPage={1}
              isFixedSize
              defaultSortKey="issueKey"
              defaultSortOrder="DESC"
            />
          </Wrapper>
          <MaintenanceForm
            modal={modal}
            toggleModal={toggleModal}
            formType={formType}
          />
          <IncidentForm
            modal={modalIncident}
            toggleModal={toggleModalIncident}
          />
          <ViewDetails
            formType={jsdRequestTypes && jsdRequestTypes.maintenance.name}
            modal={modalView}
            toggleModal={toggleModalView}
          />
          <ViewDetails
            formType={jsdRequestTypes && jsdRequestTypes.incident.name}
            modal={modalViewIncident}
            toggleModal={toggleModalViewIncident}
          />
        </div>
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  profile: state.profile,
  jira: state.jira,
  alerts: state.alert,
  servicedesk: state.servicedesk,
  manage: state.manage,
  jsd: state.jsd,
});

export default connect(mapStateToProps, {
  showMarkedValues,
  clearFormData,
  getOrganizations,
  setViewFormData,
  getQueueIssues,
  checkSession,
  logoutJira,
  ...manageActions,
})(withCookies(Dashboard));
