import {
  GET_RECIPIENTS,
  GET_CSV_FILES,
  UPLOAD_FILE,
  IMPORT_CSV_FILE,
  GET_DB_CUSTOMERS,
  SET_JSD_CUSTOMERS,
  SET_LOADING_BUTTON,
  SET_EDIT_DATA,
  SET_RECIPIENT_FORM_DATA,
  UPDATE_USER,
  GET_DB_PROVIDERS,
  CREATE_PROVIDER,
  SET_LOADING_SELECT,
  UPDATE_NOTIF,
  UPDATE_NOTIF_MAIN,
  UPDATE_NOTIF_CHANGE,
  ADD_RECIPIENT,
  DELETE_CUSTOMER,
  CREATE_DB_ORGANIZATION_RESPONSE,
  GET_DB_ORGANIZATION_RESPONSE,
  SET_JSD_MAPPINGS,
  SET_OPTIONS_ORGS,
  SET_SEARCH_INPUT,
  DELETE_ORGANIZATION,
} from '../actions/types';

const initialState = {
  addData: {
    name: '',
    organisationId: null,
    organisation: '',
    organisationInput: '',
    notifIncident: false,
    notifChange: false,
    notifMaintenance: false,
  },
  editData: {
    customerId: '',
    name: '',
    organisationId: null,
    organisation: null,
    notifIncident: '',
    notifChange: '',
    notifMaintenance: '',
  },
  recipients: [],
  csvFiles: [],
  csvFile: null,
  dbCustomers: null,
  loading: false,
  jsdCustomers: [],
  updatedUser: null,
  deleteUser: false,
  deleteOrganization: false,
  addUser: [],
  providers: [],
  provider: null,
  loadingSelect: false,
  upload: null,
  jsdMappings: null,
  createDbOrgResp: null,
  getDbOrgResp: null,
  dbOrganizations: [],
  optionsOrg: [],
  searchInput: '',
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case UPLOAD_FILE:
      return {
        ...state,
        upload: payload,
      };
    case GET_RECIPIENTS:
      return {
        ...state,
        recipients: payload,
      };
    case GET_CSV_FILES:
      return {
        ...state,
        csvFiles: payload,
      };
    case IMPORT_CSV_FILE:
      return {
        ...state,
        csvFile: payload,
      };
    case GET_DB_CUSTOMERS:
      return {
        ...state,
        dbCustomers: payload,
      };
    case SET_LOADING_BUTTON:
      return {
        ...state,
        loading: payload,
      };
    case SET_LOADING_SELECT:
      return {
        ...state,
        loadingSelect: payload,
      };
    case SET_JSD_CUSTOMERS:
      return {
        ...state,
        jsdCustomers: payload,
      };
    case SET_EDIT_DATA:
      const newData = { ...state.editData };
      newData[payload.field] = payload.value;
      return {
        ...state,
        editData: newData,
      };
    case SET_RECIPIENT_FORM_DATA:
      const addNewData = { ...state.addData };
      addNewData[payload.field] = payload.value;
      return {
        ...state,
        addData: addNewData,
      };
    case UPDATE_USER:
      return {
        ...state,
        updatedUser: payload,
      };
    case ADD_RECIPIENT:
      return {
        ...state,
        // addRecipient: payload,
        addUser: payload,
        loading: false,
      };
    case DELETE_CUSTOMER:
      return {
        ...state,
        deleteUser: payload,
      };
    case GET_DB_PROVIDERS:
      return {
        ...state,
        providers: payload,
      };
    case CREATE_PROVIDER:
      return {
        ...state,
        provider: payload,
        loadingSelect: false,
      };
    case UPDATE_NOTIF:
      return {
        ...state,
        recipients: state.recipients.map((item) => {
          if (item._id === payload._id) {
            return payload;
          }
          return item;
        }),
      };
    case CREATE_DB_ORGANIZATION_RESPONSE:
      return {
        ...state,
        createDbOrgResp: payload,
      };
    case GET_DB_ORGANIZATION_RESPONSE:
      return {
        ...state,
        getDbOrgResp: payload,
        dbOrganizations: payload.data,
      };
    case DELETE_ORGANIZATION:
        return {
          ...state,
          deleteOrganization: payload,
        };
    case SET_OPTIONS_ORGS:
      return {
        ...state,
        optionsOrg: payload,
      };
    case SET_JSD_MAPPINGS:
      return {
        ...state,
        jsdMappings: payload,
      };
    case SET_SEARCH_INPUT:
      return {
        ...state,
        searchInput: payload,
      };
    default:
      return state;
  }
}
