import React, { Fragment, useMemo, useCallback, useEffect } from 'react';
import { connect } from 'react-redux';
import {
  uploadFile,
  getCsvFiles,
  importCsvFile,
  downloadFile,
} from '../../actions/manage';
import { useDropzone } from 'react-dropzone';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { statusFormatter, countFormatter, dateFormatter } from '../../utils';
import { ERROR_CODE } from '../../constants';
import Button from '@atlaskit/button';
import { FaDatabase } from 'react-icons/fa';
import Tooltip from '@atlaskit/tooltip';
import ArrowLeftCircleIcon from '@atlaskit/icon/glyph/arrow-left-circle';

const baseStyle = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '20px',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: '#eeeeee',
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
  color: '#bdbdbd',
  outline: 'none',
  transition: 'border .24s ease-in-out',
};

const activeStyle = {
  borderColor: '#2196f3',
};

const acceptStyle = {
  borderColor: '#00e676',
};

const rejectStyle = {
  borderColor: '#ff1744',
};

const Upload = ({
  history,
  manage: { upload, csvFiles },
  uploadFile,
  getCsvFiles,
  importCsvFile,
  downloadFile,
}) => {
  // eslint-disable-next-line
  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.forEach((file) => {
      const reader = new FileReader();

      reader.onabort = () => console.log('file reading was aborted');
      reader.onerror = () => console.log('file reading has failed');
      reader.onload = () => {
        // Do whatever you want with the file contents
        const binaryStr = reader.result;
        console.log(binaryStr);
        const blob = new Blob([new Uint8Array(binaryStr)], { type: file.type });
        const formData = new FormData();
        formData.append('uploaded_file', blob, file.path);
        // formData.append('filename', file.path);
        uploadFile(file.path, formData);
      };
      reader.readAsArrayBuffer(file);
    }, []);
  });

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    onDrop,
    accept: '.csv, application/vnd.ms-excel, text/csv',
  });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject, isDragAccept]
  );

  const handleImportCsv = (filename) => {
    importCsvFile(filename);
  };

  const saveFileFormatter = (cell, row) => {
    return (
      <Button
        appearance="primary"
        onClick={() => handleImportCsv(row.filename)}
        isDisabled={row.statusCode !== ERROR_CODE.ok.code}>
        <span>
          <FaDatabase className="mr-2" />
          Import
        </span>
      </Button>
    );
  };

  const handleDownload = (filename) => {
    downloadFile(filename);
  };

  const filenameFormatter = (cell, row, rowIndex) => {
    return (
      <div>
        <Tooltip content="Download CSV file">
          <a
            id={`csv-${rowIndex}`}
            href="#foo"
            className="anchor-action"
            onClick={(e) => {
              e.preventDefault();
              handleDownload(cell);
            }}>
            {cell}
          </a>
        </Tooltip>
      </div>
    );
  };

  useEffect(() => {
    getCsvFiles();
  }, [upload, uploadFile, getCsvFiles]);

  const defaultSorted = [
    {
      dataField: 'mtime',
      order: 'desc',
    },
  ];

  const options = {
    // pageStartIndex: 0,
    sizePerPage: 10,
    hideSizePerPage: true,
    hidePageListOnlyOnePage: true,
  };

  const columns = [
    {
      dataField: 'id',
      text: 'ID',
      hidden: true,
    },
    {
      dataField: 'filename',
      text: 'Filename',
      formatter: filenameFormatter,
      sort: true,
      headerStyle: {
        width: '120px',
      },
      style: {
        wordWrap: 'break-word',
      },
    },
    {
      dataField: 'row',
      text: 'Records',
      formatter: countFormatter,
      headerStyle: {
        width: '50px',
      },
    },
    {
      dataField: 'size',
      text: 'Size (bytes)',
      headerStyle: {
        width: '60px',
      },
    },
    {
      dataField: 'mtime',
      text: 'Last modified',
      formatter: dateFormatter,
      sort: true,
      headerStyle: {
        width: '120px',
      },
    },
    {
      dataField: 'statusCode',
      text: 'Status',
      formatter: statusFormatter,
      headerStyle: {
        width: '100px',
      },
    },
    {
      dataField: 'filename',
      text: 'Action',
      formatter: saveFileFormatter,
      headerStyle: {
        width: '80px',
      },
    },
  ];

  return (
    <Fragment>
      <div className="container-fluid main-bg min-vh-100">
        <div className="container-fluid py-3">
          <div className="container upload-panel inner-bg">
            <div className="m-4">
              <section>
                <Button
                  iconBefore={
                    <ArrowLeftCircleIcon
                      size="small"
                      label="Go back to dashboard"
                    />
                  }
                  onClick={() => history.push('/dashboard')}>
                  BACK
                </Button>
                <div className="mt-3">
                  <h3>Upload Recipients</h3>
                  <br />
                  <div {...getRootProps({ style })}>
                    <input {...getInputProps()} />
                    {isDragAccept && (
                      <span className="align-middle">
                        All files will be accepted{' '}
                      </span>
                    )}
                    {isDragReject && (
                      <span className="align-middle">
                        Some files will be rejected{' '}
                      </span>
                    )}
                    {!isDragActive && (
                      <span className="align-middle">
                        Drop CSV files here ...{' '}
                      </span>
                    )}
                  </div>
                </div>
              </section>
              <section>
                <div className="mt-3">
                  <BootstrapTable
                    keyField="id"
                    data={csvFiles}
                    columns={columns}
                    defaultSorted={defaultSorted}
                    bordered={false}
                    pagination={paginationFactory(options)}
                    striped
                    bootstrap4
                  />
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  manage: state.manage,
});

export default connect(mapStateToProps, {
  uploadFile,
  getCsvFiles,
  importCsvFile,
  downloadFile,
  // getRequestParticipantIds
})(Upload);
