import React from 'react';
import { Badge } from 'reactstrap';
import { ERROR_CODE } from '../constants';
import { CSVLink } from 'react-csv';
import Tooltip from '../components/layout/Tooltip';
import Lozenge from '@atlaskit/lozenge';
import Avatar from '@atlaskit/avatar';

export const notifFormatter = (cell) => {
  if (cell) {
    return (
      <Badge className="d-inline" color="primary" pill>
        YES
      </Badge>
    );
  } else {
    return (
      <Badge className="d-inline" color="warning" pill>
        NO
      </Badge>
    );
  }
};

export const statusFormatter = (cell, row, rowIndex) => {
  switch (cell) {
    case ERROR_CODE.ok.code: {
      return <a className="text-info">{ERROR_CODE.ok.msg}</a>;
    }
    case ERROR_CODE.csvFormatError.code: {
      return (
        <a href="#foo" onClick={''} className="text-danger">
          {ERROR_CODE.csvFormatError.msg}
        </a>
      );
    }
    case ERROR_CODE.csvValueError.code: {
      const csvData = row.errorMessages.map((row) => {
        return [row.line, row.error, ...Object.values(row.data)];
      });
      return (
        <div>
          <CSVLink
            id={`csvError-${rowIndex}`}
            className="text-danger"
            data={csvData}
            filename={`${row.filename}.err.csv`}
            target="_blank">
            {ERROR_CODE.csvValueError.msg}
          </CSVLink>
          <Tooltip
            placement="top"
            target={`csvError-${rowIndex}`}
            description="Download File">
            Download File
          </Tooltip>
        </div>
      );
    }
  }
};

export const countFormatter = (cell) => {
  return cell ? cell : '-';
};

export const dateFormatter = (cell) => {
  return new Date(cell).toISOString();
};

export const participantFormatter = (cell) => {
  return cell ? (
    <p className="text-success">YES</p>
  ) : (
    <p className="text-danger">NO</p>
  );
};

export const parseSummary = (data) => {
  let list,
    item,
    summary = {};
  list = data.split(' - ');

  item = list[0];
  summary.type = item;

  item = list[1];
  summary.provider = item;

  item = list[2];
  summary.productRef = item;

  item = list[3];
  summary.providerTicketNo = item;

  return summary;
};

export const nameFormatter = (name, customerId, avatarUrls) => {
  if (customerId) {
    return (
      <div className="d-inline-flex align-items-center">
        <Avatar
          size="small"
          status="approved"
          src={avatarUrls && avatarUrls['32x32']}
        />
        &nbsp;&nbsp;
        <span className="text-jira">{name}</span>
      </div>
    );
  } else {
    return (
      <div className="d-inline-flex align-items-center">
        <Avatar
          size="small"
          status="declined"
          src={avatarUrls && avatarUrls['32x32']}
        />
        &nbsp;&nbsp;
        <span className="text-secondary">{name}</span>
      </div>
    );
  }
};

export const orgFormatter = (organisation, organisationId) => {
  if (organisationId) {
    return <span className="text-jira">{organisation}</span>;
  } else {
    return <span className="text-secondary">{organisation}</span>;
  }
};

export const organizationTableFormatter = (organization, value, isLink) => {
  if (organization.id) {
    if (isLink) {
      return <a target='_blank' href={value} className="text-jira">{value}</a>;
    }
    return <span className="text-jira">{value}</span>;
  } else {
    if (isLink) {
      return <a target='_blank' href={value} className="text-secondary">{value}</a>;
    }
    return <span className="text-secondary">{value}</span>;
  }
}

export const dateToUtc = (date) => {
  let options = {
    weekday: 'short',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    timeZone: 'UTC',
    timeZoneName: 'short',
  };
  let utcDate = new Date(
    Date.UTC(
      date.getFullYear(),
      date.getMonth(),
      date.getDate(),
      date.getHours(),
      date.getMinutes(),
      date.getSeconds(),
      date.getMilliseconds()
    )
  );

  return utcDate.toLocaleString('en-GB', options);
};

export const parse = (str, field, nextField = null) => {
  if (nextField) {
    return str.substring(
      str.indexOf(field) + field.length + 2,
      str.indexOf(nextField)
    );
  } else {
    return str.substring(str.indexOf(field) + field.length + 2);
  }
};

export const ticketStatusFormatter = (cell) => {
  const st2 = ['CANCELLED', 'DONE'];

  if (st2.includes(cell)) {
    return <Lozenge appearance="success">{cell}</Lozenge>;
  } else {
    return <Lozenge appearance="inprogress">{cell}</Lozenge>;
  }
};
