import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Dashboard from '../dashboard/Dashboard';
import Upload from '../dashboard/Upload';
import Manage from '../dashboard/Manage';
import Organization from '../dashboard/Organization';
import User from '../dashboard/User';
import Provider from '../dashboard/Provider';
import NotFound from '../layout/NotFound';

const Routes = () => {
  return (
    <section>
      <Switch>
        <Route exact path='/dashboard' component={Dashboard} />
        <Route exact path='/upload' component={Upload} />
        <Route exact path='/manage' component={Manage} />
        <Route exact path='/organization' component={Organization} />
        <Route exact path='/user' component={User} />
        <Route exact path='/provider' component={Provider} />
        <Route component={NotFound} />
      </Switch>
    </section>
  );
};

export default Routes;
