import React, { Fragment } from 'react';
import { connect } from 'react-redux';

import { setProviderFormData } from '../../actions/provider';
import Form, {
  Field,
} from '@atlaskit/form';
import TextField from '@atlaskit/textfield';
import ModalDialog, { ModalTransition } from '@atlaskit/modal-dialog';

const ProviderAdd = ({
  modaladd,
  toggleAdd,
  addProviderData: {
    name,
  },
  setProviderFormData,
  addProvider
}) => {
  return (
    <div>
      <ModalTransition>
        {modaladd && (
          <ModalDialog
            heading="Add Provider"
            onClose={toggleAdd}
            actions={[
              { text: 'Submit', type: 'submit', form: 'form-add' },
              { text: 'Cancel', onClick: toggleAdd },
            ]}>
            <Form onSubmit={addProvider}>
              {({ formProps }) => (
                <form id="form-add" {...formProps}>
                  <Field
                    name="name"
                    label="Name"
                    defaultValue=""
                    isRequired
                    value={name}>
                    {({ fieldProps, error, valid }) => (
                      <Fragment>
                        <TextField
                          {...fieldProps}
                          value={name}
                          onChange={(newValue) => {
                            return (
                              newValue &&
                              setProviderFormData(
                                'name',
                                newValue.target.value
                              )
                            );
                          }}
                        />
                      </Fragment>
                    )}
                  </Field>
                </form>
              )}
            </Form>
          </ModalDialog>
        )}
      </ModalTransition>
    </div>
  );
};

const mapStateToProps = (state) => ({
  addProviderData: state.provider.addProviderData,
});

export default connect(mapStateToProps, {
  setProviderFormData,
})(ProviderAdd);
