import axios from 'axios';
import {
  GET_QUEUES,
  POST_ERROR,
  GET_QUEUE_ISSUES,
  SET_REQUESTS,
  GET_REQUEST_BY_ID,
  AUTH_ERROR,
  SET_LOADING,
  SET_LOADING_MODAL,
  SET_JSD_QUEUE_SIZE,
  GET_JSD_ORGANIZATIONS,
  CREATE_CUSTOMER_REQUEST,
  GET_INFO,
  LOGOUT,
  SET_CHECK_SESSION,
} from './types';
import { setAlert } from './alert';
import { v4 as uuidv4 } from 'uuid';
import { parse } from '../utils';
import { dateToUtc } from '../utils';

const _config = window._env_;

axios.defaults.withCredentials = true;

export const getQueues = () => async (dispatch) => {
  try {
    const res = await axios.get(`${_config.MAT_URL}/api/1/jsd/queues`);

    dispatch({
      type: GET_QUEUES,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: POST_ERROR,
      payload: { err },
    });
  }
};

export const getRequestById = (issueId) => async (dispatch) => {
  const params = {
    issueId,
  };
  dispatch({
    type: SET_LOADING_MODAL,
    payload: true,
  });

  try {
    const res = await axios.get(`${_config.MAT_URL}/api/1/jsd/request`, {
      params,
    });

    dispatch({
      type: GET_REQUEST_BY_ID,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: POST_ERROR,
      payload: { err },
    });

    dispatch({
      type: SET_LOADING_MODAL,
      payload: false,
    });
  }
};

export const getQueueIssues = (jsdMappings) => async (dispatch) => {
  const { jsdRequestType: jsdRequestTypes } = jsdMappings;
  dispatch({
    type: SET_LOADING,
    payload: true,
  });

  try {
    let resultsArray = [];
    let lastPage = false;
    let start = 0;

    do {
      const res = await axios.get(`${_config.MAT_URL}/api/1/jsd/queue-issues`, {
        params: { start },
      });
      const { size, isLastPage, values } = res.data;
      lastPage = isLastPage;
      resultsArray.push(values);
      start = start + size;
    } while (!lastPage);

    const mergedResults = resultsArray.flat();

    dispatch({
      type: GET_QUEUE_ISSUES,
      payload: mergedResults,
    });

    dispatch({
      type: SET_JSD_QUEUE_SIZE,
      payload: start,
    });

    const parseSummary = (summary, field) => {
      let list, item;
      if (summary && field === 'type') {
        list = summary.split(' - ');
        item = list[0];
        return item;
      } else if (summary && field === 'provider') {
        list = summary.split(' - ');
        item = list[1];
        return item;
      } else if (summary && field === 'productRef') {
        list = summary.split(' - ');
        item = list[2];
        return item;
      } else if (summary && field === 'providerTicketNo') {
        list = summary.split(' - ');
        item = list[3];
        return item;
      } else if (summary && field === 'providerTicketNo') {
        list = summary.split(' - ');
        item = list[4];
        return item;
      }
      return '-';
    };

    const requests =
      mergedResults.map(
        ({
          id,
          key,
          fields: {
            summary,
            issuetype: { id: requestTypeId },
            description,
            status: { name: statusName },
          },
        }) => {
          const type = summary && parseSummary(summary, 'type');
          summary && parseSummary(summary, 'providerTicketNo');
          let startDate =
            description && parse(description, 'Start time', 'End time');
          let endDate =
            description && parse(description, 'End time', 'Backup start time');
          if (requestTypeId === jsdRequestTypes.incident.id) {
            startDate = '';
            endDate = '';
          }

          id = parseInt(id);
          let uuid = uuidv4();
          return {
            uuid,
            id,
            issueId: id,
            issueKey: key,
            requestTypeId,
            status: statusName,
            summary,
            type,
            startDate,
            endDate,
          };
        }
      ) || [];

    dispatch({
      type: SET_REQUESTS,
      payload: requests,
    });
  } catch ({ message, response: { status = {} } = {} }) {
    dispatch({
      type: POST_ERROR,
      payload: { message },
    });

    if (status === 401) {
      dispatch({
        type: AUTH_ERROR,
      });

      dispatch(setAlert({ msg: 'Not Authenticated', alertType: 'error' }));
    }
  }
};

export const getOrganizations = () => async (dispatch) => {
  try {
    let resultsArray = [];
    let lastPage = false;
    let start = 0;

    do {
      const res = await axios.get(`${_config.MAT_URL}/api/1/jsd/organization`, {
        params: { start },
      });
      const { size, isLastPage, values } = res.data;
      lastPage = isLastPage;
      resultsArray.push(values);
      start = start + size;
    } while (!lastPage);

    const mergedResults = resultsArray.flat();

    dispatch({
      type: GET_JSD_ORGANIZATIONS,
      payload: mergedResults,
    });
  } catch (err) {
    dispatch({
      type: POST_ERROR,
      payload: { err },
    });
  }
};

const getUsernameErrorMessage = async (orgId, errorData) => {
  const { data: customers } = await axios.get(`${_config.MAT_URL}/api/1/customer`);
  const orgUsers = customers.filter(customer => customer.organisationId === orgId);
  let errorMessage = errorData.errorMessage;
  if (errorData.i18nErrorMessage.i18nKey === 'sd.request.participants.username.error') {
    const usernames = [];
    const message = errorMessage.split(': ');
    errorData.i18nErrorMessage.parameters.forEach(id => {
      const user = orgUsers.find(user => user._id === id);
      user && usernames.push(user.name);
    });
    errorMessage = `${message[0]}: ${usernames.join(', ')}`;
  }
  return errorMessage;
}

export const createCustomerRequest =
  (data, type, participants, org, jsdMappings) => async (dispatch) => {
    const {
      customFields,
      jsdRequestType: jsdRequestTypes,
      requestType,
    } = jsdMappings;
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const {
      params: {
        provider,
        assignee,
        devices,
        backupDevices,
        productRef,
        providerTicketNo,
        startDate,
        endDate,
        backupStartDate,
        backupEndDate,
        purpose,
        impact,
        location,
        outage,
        organizations,
      },
    } = data;
    const { label: orgName } = organizations.find(({ value }) => {
      return org === value;
    });

    const maintenanceType =
      type === requestType.planned.name
        ? requestType.planned.name
        : requestType.emergency.name;

    let payload = {
      requestTypeId: jsdRequestTypes.maintenance.id,
      requestParticipants: participants,
      requestFieldValues: {
        summary: `${maintenanceType} - INTERCLOUD ${devices}`,
        description: `Requester: ${provider}\nAssignee: ${assignee || 'None'}\nStart time: ${dateToUtc(
          startDate
        )}\nEnd time: ${dateToUtc(endDate)}\nBackup start time: ${
          backupStartDate ? dateToUtc(backupStartDate) : 'N/A'
        }\nBackup end time: ${
          backupEndDate ? dateToUtc(backupEndDate) : 'N/A'
        }\nOrganization: ${orgName}\nLocation: ${location}\nExpected impact: ${impact}\nExpected outage/downtime: ${outage}\nPurpose: ${purpose}\nProductRef: ${productRef}\nProviderTicketNo: ${providerTicketNo}\nDevices: ${devices}\nBackupDevices: ${backupDevices}`,
      },
    };
    if (customFields?.organizations) {
      payload.requestFieldValues = {
        ...payload.requestFieldValues,
        ...{ [customFields.organizations]: org ? [org] : [] },
      };
    }
    if (customFields?.plannedStart) {
      payload.requestFieldValues = {
        ...payload.requestFieldValues,
        ...{ [customFields.plannedStart]: startDate },
      };
    }
    if (customFields?.plannedEnd) {
      payload.requestFieldValues = {
        ...payload.requestFieldValues,
        ...{ [customFields.plannedEnd]: endDate },
      };
    }

    dispatch({
      type: SET_LOADING,
      payload: true,
    });

    try {
      const res = await axios.post(
        `${_config.MAT_URL}/api/1/jsd/request`,
        payload,
        config
      );

      dispatch({
        type: CREATE_CUSTOMER_REQUEST,
        payload: res.data,
      });

      dispatch(
        setAlert({
          msg: `Issue ${res.data.issueKey} was created successfully`,
          alertType: 'success',
          actions: [
            {
              content: 'View request in portal',
              onClick: () => {
                window.open(res.data._links.web, '_blank');
              },
            },
          ],
        })
      );
    } catch ({ response: { data, status } = {} }) {
      dispatch({
        type: POST_ERROR,
        payload: { msg: data, status },
      });
      dispatch({
        type: SET_LOADING,
        payload: false,
      });
      dispatch(
        setAlert({
          msg: 'An internal server error occured.',
          description: await getUsernameErrorMessage(org, data),
          alertType: 'error',
        })
      );
    }
  };

export const createIncidentRequest =
  (data, participants, org, jsdMappings) => async (dispatch) => {
    const {
      customFields,
      jsdRequestType: jsdRequestTypes,
      priority: PRIORITY,
      incidentType: INCIDENT_TYPE,
    } = jsdMappings;
    const {
      params: {
        subject,
        body,
        priority,
        incidentType,
        impactedServices,
        organizations,
      },
    } = data;

    const { label: orgName } = organizations.find(({ value }) => {
      return org === value;
    });

    const getPriorityName = (priority) => {
      const { name } = Object.values(PRIORITY).find(({ id }) => {
        return priority === id;
      });
      return name;
    };

    const getIncidentTypeName = (incidentType) => {
      const { name } = Object.values(INCIDENT_TYPE).find(({ id }) => {
        return incidentType === id;
      });
      return name;
    };

    const payload = {
      requestTypeId: jsdRequestTypes.incident.id,
      requestParticipants: participants,
      requestFieldValues: {
        summary: subject,
        description: `Description: ${body}\nPriority: ${getPriorityName(
          priority
        )}\nIncident type: ${getIncidentTypeName(
          incidentType
        )}\nImpacted services: ${impactedServices}\nOrganization: ${orgName}`,
        [customFields.priority]: { id: priority },
        [customFields.impactedServices]: impactedServices,
        [customFields.organizations]: org ? [org] : [],
      },
    };

    dispatch({
      type: SET_LOADING,
      payload: true,
    });

    try {
      const res = await axios.post(
        `${_config.MAT_URL}/api/1/jsd/request`,
        payload
      );

      dispatch({
        type: CREATE_CUSTOMER_REQUEST,
        payload: res.data,
      });

      dispatch(
        setAlert({
          msg: `Issue ${res.data.issueKey} was created successfully`,
          alertType: 'success',
          actions: [
            {
              content: 'View request in portal',
              onClick: () => {
                window.open(
                  res.data._links.web,
                  '_blank' // <- This is what makes it open in a new window.
                );
              },
            },
          ],
        })
      );
    } catch ({ response: { data, status } = {} }) {
      dispatch({
        type: POST_ERROR,
        payload: { msg: data, status },
      });
      dispatch({
        type: SET_LOADING,
        payload: false,
      });
      dispatch(
        setAlert({
          msg: 'An internal server error occured.',
          description: await getUsernameErrorMessage(org, data),
          alertType: 'error',
        })
      );
    }
  };

export const logoutJira = () => async (dispatch) => {
  try {
    await axios.post(`${_config.MAT_URL}/logout`);
    window.location.href = '/';
    dispatch({
      type: LOGOUT,
    });
  } catch (err) {
    dispatch({
      type: POST_ERROR,
      payload: { err },
    });
  }
};

export const getInfo = () => async (dispatch) => {
  try {
    const res = await axios.get(`${_config.MAT_URL}/api/1/jsd`);

    dispatch({
      type: GET_INFO,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
      payload: { err },
    });
  }
};

export const checkSession = () => async (dispatch) => {
  try {
    const res = await axios.get(`${_config.MAT_URL}/api/1/jsd/check-session`);
    dispatch({
      type: SET_CHECK_SESSION,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
      payload: { err },
    });
  }
};
