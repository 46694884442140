import { combineReducers } from 'redux';
import alert from './alert';
import jira from './jira';
import issue from './issue';
import servicedesk from './servicedesk'
import manage from './manage'
import jsd from './jsd'
import user from './user'
import organization from './organization'
import provider from './provider'

export default combineReducers({
  issue,
  servicedesk,
  manage,
  jsd,
  alert,
  jira,
  user,
  organization,
  provider,
});
