import {
  SET_FORM_DATA,
  CREATE_TICKET,
  CLEAR_FORM_DATA,
  SHOW_MARKED_VALUES,
} from '../actions/types';
import { dateToUtc } from '../utils';

const initialState = {
  params: {
    startDate: new Date(),
    endDate: new Date(),
    organizations: [],
  },
  ticket: {},
  showMark: false,
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SET_FORM_DATA:
      const newParams = { ...state.params };
      newParams[payload.field] = payload.value;
      return {
        ...state,
        params: newParams,
      };
    case SHOW_MARKED_VALUES:
      return {
        ...state,
        showMark: payload,
      };
    case CREATE_TICKET:
      return {
        ...state,
        ticket: payload,
        loading: false,
      };
    case CLEAR_FORM_DATA:
      return {
        ...state,
        params: {
          startDate: new Date(),
          endDate: new Date(),
        },
      };
    default:
      return state;
  }
}
