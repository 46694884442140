import React, { useEffect, Fragment } from 'react';
import { connect } from 'react-redux';
import { setEditData, getDbOrganizations } from '../../actions/manage';
import Form, {
  ErrorMessage,
  Field,
  Fieldset,
  CheckboxField,
  FormFooter,
  HelperMessage,
  ValidMessage,
} from '@atlaskit/form';
import TextField from '@atlaskit/textfield';
import ModalDialog, { ModalTransition } from '@atlaskit/modal-dialog';
import Button from '@atlaskit/button';
import { Checkbox } from '@atlaskit/checkbox';
import Select from '@atlaskit/select';

const Edit = ({
  modal,
  toggleEdit,
  setEditData,
  updateUser,
  getDbOrganizations,
  manage: {
    editData: {
      name,
      organisationId,
      organisation,
      notifIncident,
      notifMaintenance,
      notifChange,
    },
    dbOrganizations,
  },
}) => {
  useEffect(() => {
    getDbOrganizations();
  }, []);

  const optionsOrgs = dbOrganizations
    ? dbOrganizations.filter(org => !!org.id).map(({ id, name }) => {
        return {
          label: name,
          value: name,
        };
      })
    : [];

  return (
    <div>
      <ModalTransition>
        {modal && (
          <ModalDialog
            heading="Edit Recipient"
            onClose={toggleEdit}
            actions={[
              { text: 'Submit', type: 'submit', form: 'form-add' },
              { text: 'Cancel', onClick: toggleEdit },
            ]}>
            <Form onSubmit={updateUser}>
              {({ formProps }) => (
                <form id="form-add" {...formProps}>
                  <Field name="name" label="Name" defaultValue="" isRequired>
                    {({ fieldProps, error, valid }) => (
                      <Fragment>
                        <TextField
                          {...fieldProps}
                          value={name}
                          onChange={(newValue) => {
                            return (
                              newValue &&
                              setEditData('name', newValue.target.value)
                            );
                          }}
                        />
                        {/* {!error && !valid && (
                          <HelperMessage>
                            Should be more than 4 characters
                          </HelperMessage>
                        )} */}
                      </Fragment>
                    )}
                  </Field>
                  <Field
                    name="organization"
                    label="Select organization"
                    defaultValue={null}>
                    {({ fieldProps: { id, ...rest }, error }) => (
                      <Fragment>
                        <Select
                          inputId="select-org"
                          {...rest}
                          value={{ label: organisation, value: organisation }}
                          options={optionsOrgs}
                          onChange={(newValue) => {
                            return (
                              newValue &&
                              setEditData('organisation', newValue.label)
                            );
                          }}
                        />
                      </Fragment>
                    )}
                  </Field>
                  <Fieldset legend="Notifications">
                    <CheckboxField name="notifIncident">
                      {({ fieldProps }) => (
                        <Checkbox
                          {...fieldProps}
                          label="notif-incident"
                          isChecked={notifIncident}
                          value={notifIncident}
                          onClick={(newValue) => {
                            setEditData(
                              'notifIncident',
                              newValue.target.checked
                            );
                          }}
                        />
                      )}
                    </CheckboxField>
                    <CheckboxField name="notifChange">
                      {({ fieldProps }) => (
                        <Checkbox
                          {...fieldProps}
                          label="notif-change"
                          isChecked={notifChange}
                          value={notifChange}
                          onClick={(newValue) => {
                            setEditData('notifChange', newValue.target.checked);
                          }}
                        />
                      )}
                    </CheckboxField>
                    <CheckboxField name="notifMaintenance">
                      {({ fieldProps }) => (
                        <Checkbox
                          {...fieldProps}
                          label="notif-maintenance"
                          value={notifMaintenance}
                          isChecked={notifMaintenance}
                          onClick={(newValue) => {
                            setEditData(
                              'notifMaintenance',
                              newValue.target.checked
                            );
                          }}
                        />
                      )}
                    </CheckboxField>
                  </Fieldset>
                </form>
              )}
            </Form>
          </ModalDialog>
        )}
      </ModalTransition>
    </div>
  );
};

const mapStateToProps = (state) => ({
  manage: state.manage,
  servicedesk: state.servicedesk,
});

// const mapDispatchToProps = {

// }

export default connect(mapStateToProps, {
  setEditData,
  getDbOrganizations,
})(Edit);
