import React, { useState } from 'react';
import { Tooltip as RsTooltip } from 'reactstrap';
import PropTypes from 'prop-types';

const Tooltip = (props) => {
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const toggle = () => {
    return setTooltipOpen(!tooltipOpen);
  };
  const { target, description, placement } = props;
  return (
    <div>
      <RsTooltip
        placement={placement}
        isOpen={tooltipOpen}
        target={target}
        toggle={toggle}>
        {description}
      </RsTooltip>
    </div>
  );
};

Tooltip.propTypes = {
  placement: PropTypes.string.isRequired,
  target: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired
};

export default Tooltip;
