import { SET_ALERT, REMOVE_ALERT } from '../actions/types';

const initialState = [];

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SET_ALERT:
      const newFlagId = state.length + 1;
      const newFlags = state.slice();
      newFlags.splice(0, 0, {
        id: newFlagId,
        msg: payload.msg,
        alertType: payload.alertType,
        description: payload.description,
        actions: payload.actions || [],
      });

      return (state = newFlags);
    case REMOVE_ALERT:
      return state.slice(1);
    default:
      return state;
  }
}
