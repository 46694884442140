import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import Modal from '../layout/Modal';
import { getRequestById } from '../../actions/jsd';
import { parseSummary, parse } from '../../utils';
import Spinner from '@atlaskit/spinner';
import ModalDialog, { ModalTransition } from '@atlaskit/modal-dialog';

const ViewDetails = ({
  formType,
  modal,
  toggleModal,
  manage: { jsdMappings },
  servicedesk: { viewFormData },
  jsd: { request, loadingModal },
  getRequestById,
}) => {
  const { issueKey } = viewFormData;

  const { jsdRequestType: jsdRequestTypes } = jsdMappings || {};

  useEffect(() => {
    modal && getRequestById(issueKey);
  }, [issueKey, modal, getRequestById]);

  let title,
    summary = {},
    desc = {},
    description;
  let details = {};
  if (request && !request.hasOwnProperty('errorMessage')) {
    const {
      requestTypeId,
      requestFieldValues,
      currentStatus: {
        statusDate: { friendly },
      },
      _links: { web },
    } = request;
    title = requestFieldValues[0].value;
    description = requestFieldValues[1].value;
    if (requestTypeId === jsdRequestTypes.maintenance.id) {
      summary = parseSummary(title);
    }

    details.friendly = friendly;
    details.requestTypeId = requestTypeId;
    details.web = web;
  }

  // const { currentStatus: { statusDate: { friendly }}} = request || {};
  return (
    <div>
      {jsdMappings &&
      details.requestTypeId === jsdRequestTypes.maintenance.id ? (
        <ModalTransition>
          {modal && (
            <ModalDialog
              actions={[
                { text: 'Close', onClick: toggleModal, appearance: 'subtle' },
              ]}
              onClose={toggleModal}
              heading={`${jsdRequestTypes.maintenance.name} Announcement`}
              width="x-large">
              <div className="container-fluid">
                {loadingModal ? (
                  <div
                    style={{ height: '549px' }}
                    className="d-flex justify-content-center align-items-center">
                    <div>
                      <Spinner size="large" />
                    </div>
                  </div>
                ) : (
                  <div>
                    <div>
                      <h5>{title}</h5>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <br />
                        <small>
                          <strong>Created at</strong>
                        </small>
                        <p>{details.friendly}</p>
                        <small>
                          <strong>Type</strong>
                        </small>
                        <p>{summary.type}</p>
                        <small>
                          <strong>Provider</strong>
                        </small>
                        <p>{summary.provider}</p>
                        <small>
                          <strong>Assignee</strong>
                        </small>
                        <p>{parse(description, 'Assignee', 'Start time')}</p>
                        <small>
                          <strong>Product Reference</strong>
                        </small>
                        <p>{parse(description, 'ProductRef', 'ProviderTicketNo')}</p>
                        <small>
                          <strong>Provider Ticket Number</strong>
                        </small>
                        <p>{parse(description, 'ProviderTicketNo', 'Devices')}</p>
                        <small>
                          <strong>Start Date UTC</strong>
                        </small>
                        <p>{parse(description, 'Start time', 'End time')}</p>
                        <small>
                          <strong>End Date UTC</strong>
                        </small>
                        <p>
                          {parse(description, 'End time', 'Backup start time')}
                        </p>
                        <small>
                          <strong>Backup Start Date UTC</strong>
                        </small>
                        <p>
                          {parse(
                            description,
                            'Backup start time',
                            'Backup end time'
                          )}
                        </p>
                        <small>
                          <strong>Backup End Date UTC</strong>
                        </small>
                        <p>
                          {parse(
                            description,
                            'Backup end time',
                            'Organization'
                          )}
                        </p>
                      </div>
                      <div className="col-md-6">
                        <br />
                        <small>
                          <strong>Organization</strong>
                        </small>
                        <p>{parse(description, 'Organization', 'Location')}</p>
                        <small>
                          <strong>Jira Ticket</strong>
                        </small>
                        <br />
                        <a
                          href={details.web}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="anchor-action">
                          {issueKey}
                        </a>
                        <br />
                        <small>
                          <strong>Impact</strong>
                        </small>
                        <p>
                          {parse(
                            description,
                            'Expected impact',
                            'Expected outage/downtime'
                          )}
                        </p>
                        <small>
                          <strong>Purpose</strong>
                        </small>
                        <p>{parse(description, 'Purpose', 'ProductRef')}</p>
                        <small>
                          <strong>Location</strong>
                        </small>
                        <p>
                          {parse(description, 'Location', 'Expected impact')}
                        </p>
                        <small>
                          <strong>Devices(s)</strong>
                        </small>
                        <p>
                          {parse(description, 'Devices', 'BackupDevices')}
                        </p>
                        <small>
                          <strong>Backup Device(s)</strong>
                        </small>
                        <p>
                          {parse(description, 'BackupDevices')}
                        </p>
                        <small>
                          <strong>Outage</strong>
                        </small>
                        <p>
                          {parse(
                            description,
                            'Expected outage/downtime',
                            'Purpose'
                          )}
                        </p>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </ModalDialog>
          )}
        </ModalTransition>
      ) : (
        <ModalTransition>
          {modal && (
            <ModalDialog
              actions={[
                { text: 'Close', onClick: toggleModal, appearance: 'subtle' },
              ]}
              onClose={toggleModal}
              heading={`${jsdRequestTypes.incident.name} Announcement`}
              width="x-large">
              <div className="container-fluid">
                {loadingModal ? (
                  <div
                    style={{ height: '500px' }}
                    className="d-flex justify-content-center align-items-center">
                    <div>
                      <Spinner size="large" />
                    </div>
                  </div>
                ) : (
                  <div>
                    <div>
                      <div>
                        <h5>{title}</h5>
                      </div>
                      <div className="row">
                        <div className="col-md-12">
                          <br />
                          <small>
                            <strong>Created at</strong>
                          </small>
                          <p>{details.friendly}</p>
                          <small>
                            <strong>Jira Ticket</strong>
                          </small>
                          <br />
                          <a
                            href={details.web}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="anchor-action">
                            {issueKey}
                          </a>
                          <br />
                          <br />
                          <small>
                            <strong>Subject</strong>
                          </small>
                          <p>{title}</p>

                          <small>
                            <strong>Body</strong>
                          </small>
                          <p>{parse(description, 'Description', 'Priority')}</p>
                          <small>
                            <strong>Priority</strong>
                          </small>
                          <p>
                            {parse(description, 'Priority', 'Incident type')}
                          </p>
                          <small>
                            <strong>Incident Type</strong>
                          </small>
                          <p>
                            {parse(
                              description,
                              'Incident type',
                              'Impacted services'
                            )}
                          </p>
                          <small>
                            <strong>Impacted Services</strong>
                          </small>
                          <p>
                            {parse(
                              description,
                              'Impacted services',
                              'Organization'
                            )}
                          </p>
                          <small>
                            <strong>Organizations</strong>
                          </small>
                          <p>{parse(description, 'Organization')}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </ModalDialog>
          )}
        </ModalTransition>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  jsd: state.jsd,
  servicedesk: state.servicedesk,
  manage: state.manage,
});

const mapDispatchToProps = {
  getRequestById,
};

export default connect(mapStateToProps, mapDispatchToProps)(ViewDetails);
