import axios from 'axios';
import { setAlert } from './alert';
import {
  ADD_USER,
  DELETE_ORGANIZATION,
  POST_ERROR,
  SET_LOADING_SELECT,
  SET_ORGANIZATION_FORM_DATA,
  UPDATE_ORGANIZATION,
} from './types';

const _config = window._env_;

axios.defaults.withCredentials = true;

export const createOganization = (payload) => async (dispatch) => {
  try {
    dispatch({
      type: SET_LOADING_SELECT,
      payload: true,
    });
    const res = await axios.post(`${_config.MAT_URL}/api/1/organization`, payload);
    dispatch({
      type: ADD_USER,
      payload: res.data,
    });
    dispatch(
      setAlert({
        msg: `${payload.name} created`,
        alertType: 'success',
        description: 'Record will be saved locally.',
      })
    );
  } catch (err) {
    dispatch({
      type: POST_ERROR,
      payload: { err },
    });
    dispatch(
      setAlert({
        msg: `User ${payload.name} creation failed`,
        alertType: 'error',
      })
    );
    dispatch({
      type: SET_LOADING_SELECT,
      payload: false,
    });
  }
};

export const setOrganizationFormData = (field, value) => async (dispatch) => {
  console.log(field, value)
  dispatch({
    type: SET_ORGANIZATION_FORM_DATA,
    payload: { field, value },
  });
};

export const removeOrganization = (payload) => async (dispatch) => {
  try {
    const res = await axios.delete(
      `${_config.MAT_URL}/api/1/organization/delete/${payload._id}`
    );
    dispatch({
      type: DELETE_ORGANIZATION,
      payload: res.data,
    });
    dispatch(
      setAlert({
        msg: `${payload.name} deleted`,
        alertType: 'success',
        description: 'Record will only be deleted locally.',
      })
    );
  } catch (err) {
    dispatch({
      type: POST_ERROR,
      payload: { err },
    });
    dispatch(
      setAlert({
        msg: `User ${payload.name} deletion failed`,
        alertType: 'error',
      })
    );
    console.log(JSON.stringify(payload));
  }
};

export const updateOrganization = (payload) => async (dispatch) => {
  try {
    const res = await axios.post(
      `${_config.MAT_URL}/api/1/organization/update`,
      payload
    );
    dispatch({
      type: UPDATE_ORGANIZATION,
      payload: res.data,
    });
    dispatch(
      setAlert({
        msg: `${res.data.name} updated`,
        alertType: 'success',
        description: 'Record will be saved locally.',
      })
    );
  } catch (err) {
    dispatch({
      type: POST_ERROR,
      payload: { err },
    });
  }
};


