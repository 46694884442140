export const SET_ALERT = 'SET_ALERT';
export const REMOVE_ALERT = 'REMOVE_ALERT';
export const AUTH_ERROR = 'AUTH_ERROR';
export const POST_ERROR = 'POST_ERROR';
export const GET_CLOUD_ID = 'GET_CLOUD_ID';
export const GET_ALL_TICKETS = 'GET_ALL_TICKETS';
export const GET_ALL_PROJECTS = 'GET_ALL_PROJECTS';
export const GET_GROUPS = 'GET_GROUPS';
export const USER_LOADED = 'USER_LOADED';
export const SET_FORM_DATA = 'SET_FORM_DATA';
export const CREATE_TICKET = 'CREATE_TICKET';
export const CLEAR_FORM_DATA = 'CLEAR_FORM_DATA';
export const SHOW_MARKED_VALUES = 'SHOW_MARKED_VALUES';
export const LOGOUT = 'LOGOUT';

export const CREATE_DB_ORGANIZATION_RESPONSE =
  'CREATE_DB_ORGANIZATION_RESPONSE';
export const ADD_ORGANIZATION_RESPONSE = 'ADD_ORGANIZATION_RESPONSE';
export const GET_CUSTOMERS = 'GET_CUSTOMERS';
export const GET_SERVICE_DESKS = 'GET_SERVICE_DESKS';
export const GET_CUSTOMER_REQUESTS = 'GET_CUSTOMER_REQUESTS';
export const GET_JSD_ORGANIZATIONS = 'GET_JSD_ORGANIZATIONS';
export const GET_DB_ORGANIZATIONS = 'GET_DB_ORGANIZATIONS';
export const SET_REQUESTS = 'SET_REQUESTS';
export const CREATE_CUSTOMER_REQUEST = 'CREATE_CUSTOMER_REQUEST';
export const SET_VIEW_FORM_DATA = 'SET_VIEW_FORM_DATA';
export const GET_REQUEST_TYPES = 'GET_REQUEST_TYPES';
export const SET_OAUTH_TOKEN = 'SET_OAUTH_TOKEN';
export const GET_REQUEST_PARTICIPANT_IDS = 'GET_REQUEST_PARTICIPANT_IDS';
export const UPLOAD_FILE = 'UPLOAD_FILE';
export const GET_RECIPIENTS = 'GET_RECIPIENTS';
export const GET_CSV_FILES = 'GET_CSV_FILES';
export const IMPORT_CSV_FILE = 'IMPORT_CSV_FILE';
export const GET_ORG_USERS = 'GET_ORG_USERS';
export const GET_ORGANIZATION_USERS = 'GET_ORGANIZATION_USERS';
export const GET_DB_CUSTOMERS = 'GET_DB_CUSTOMERS';
export const SAVE_ORG_USERS = 'SAVE_ORG_USERS';
export const GET_QUEUES = 'GET_QUEUES';
export const GET_QUEUE_ISSUES = 'GET_QUEUE_ISSUES';
export const GET_REQUEST_BY_ID = 'GET_REQUEST_BY_ID';
export const SET_LOADING = 'SET_LOADING';
export const SET_LOADING_MODAL = 'SET_LOADING_MODAL';
export const SET_LOADING_BUTTON = 'SET_LOADING_BUTTON';
export const SET_LOADING_SELECT = 'SET_LOADING_SELECT';
export const GET_INFO = 'GET_INFO';
export const SET_JSD_CUSTOMERS = 'SET_JSD_CUSTOMERS';
export const SET_EDIT_DATA = 'SET_EDIT_DATA';
export const SET_RECIPIENT_FORM_DATA = 'SET_RECIPIENT_FORM_DATA';
export const UPDATE_USER = 'UPDATE_USER';
export const ADD_RECIPIENT = 'ADD_RECIPIENT';
export const CREATE_PROVIDER = 'CREATE_PROVIDER';
export const GET_DB_PROVIDERS = 'GET_DB_PROVIDERS';
export const SET_JSD_QUEUE_SIZE = 'SET_JSD_QUEUE_SIZE';
export const UPDATE_NOTIF = 'UPDATE_NOTIF';
export const UPDATE_NOTIF_MAIN = 'UPDATE_NOTIF_MAIN';
export const UPDATE_NOTIF_CHANGE = 'UPDATE_NOTIF_CHANGE';
export const GET_DB_ORGS = 'GET_DB_ORGS';
export const DELETE_CUSTOMER = 'DELETE_CUSTOMER';
export const CLEAR_ADD_FORM_DATA = 'CLEAR_ADD_FORM_DATA';
export const SET_CHECK_SESSION = 'SET_CHECK_SESSION';
export const SET_JSD_MAPPINGS = 'SET_JSD_MAPPINGS';
export const GET_DB_JSD_MAPPINGS = 'GET_DB_JSD_MAPPINGS';
export const GET_DB_ORGANIZATION_RESPONSE = 'GET_DB_ORGANIZATION_RESPONSE';
export const UPDATE_DB_ORGANIZATION_RESPONSE =
  'UPDATE_DB_ORGANIZATION_RESPONSE';
export const SET_OPTIONS_ORGS = 'SET_OPTIONS_ORGS';
export const SET_SEARCH_INPUT = 'SET_SEARCH_INPUT';
export const DELETE_ORGANIZATION = 'DELETE_ORGANIZATION';
export const GET_USERS = 'GET_USERS';
export const SET_USER_FORM_DATA = 'SET_USER_FORM_DATA';
export const ADD_USER = 'ADD_USER';
export const DELETE_USER = 'DELETE_USER';
export const ADD_ORGANIZATION = 'ADD_ORGANIZATION';
export const UPDATE_ORGANIZATION = 'ADD_ORGANIZATION';
export const GET_ORGANIZATIONS = 'GET_ORGANIZATIONS';
export const SET_ORGANIZATION_FORM_DATA = 'SET_ORGANIZATION_FORM_DATA';
export const UPDATE_PROVIDER = 'UPDATE_PROVIDER';
export const SET_PROVIDER_FORM_DATA = 'SET_PROVIDER_FORM_DATA';
export const DELETE_PROVIDER = 'DELETE_PROVIDER';
export const GET_PROVIDERS = 'GET_PROVIDERS';