import React, { useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { setFormData, showMarkedValues } from '../../actions/issue';
import { Input } from 'reactstrap';
import { Label } from '@atlaskit/field-base';

import { getQueueIssues, createIncidentRequest } from '../../actions/jsd';
import { getJsdMappings, getDbOrganizations } from '../../actions/manage';
import Form, {
  ErrorMessage,
  Field,
  Fieldset,
  CheckboxField,
  FormFooter,
  HelperMessage,
  ValidMessage,
} from '@atlaskit/form';
import TextField from '@atlaskit/textfield';
import TextArea from '@atlaskit/textarea';
import ModalDialog, { ModalTransition } from '@atlaskit/modal-dialog';

import Select, { CreatableSelect } from '@atlaskit/select';
import styled from 'styled-components';

const WrapperMedium = styled.div`
  max-width: 316px;
`;
const WrapperLarge = styled.div`
  max-width: 492px;
`;

const IncidentForm = ({
  issue,
  getQueueIssues,
  modal,
  toggleModal,
  formType,
  jira: { token, cloudId },
  manage: { recipients, jsdMappings, dbOrganizations },
  setFormData,
  showMarkedValues,
  createIncidentRequest,
  getJsdMappings,
  getDbOrganizations,
}) => {
  const {
    params: {
      subject,
      body,
      priority,
      incidentType,
      impactedServices,
      organizations,
    },
    showMark,
  } = issue;

  useEffect(() => {
    getDbOrganizations();
  }, []);
  // useEffect(() => {
  //   request && getQueueIssues();
  // }, [request, getQueueIssues]);

  const removeDuplicates = (myArr, prop) => {
    return myArr.filter((obj, pos, arr) => {
      return arr.map((mapObj) => mapObj[prop]).indexOf(obj[prop]) === pos;
    });
  };

  const distinctOrgs = removeDuplicates(
    recipients
      .filter(({ organisationId }) => {
        return organisationId;
      })
      .map(({ organisation, organisationId }) => {
        return { organisationId, organisation };
      }),
    'organisationId'
  );

  const handleCreateIssue = () => {
    const { params } = issue;

    showMarkedValues(true);
    let customerIds;
    organizations
      .map(({ value }) => {
        return value;
      })
      .forEach((org) => {
        customerIds = recipients
          .filter(({ organisationId, notifIncident, customerId }) => {
            return notifIncident && customerId && organisationId === org;
          })
          .map(({ customerId }) => {
            return customerId;
          });
        issue.params.subject = issue.params.subject.trim();
        issue.params.body = issue.params.body?.trim();
        issue.params.impactedServices = issue.params.impactedServices?.trim();
        createIncidentRequest(issue, customerIds, org, jsdMappings);
      });
    toggleModal();
  };

  const optionsOrg = dbOrganizations
    ? dbOrganizations.filter(org => !!org.id).map(({ name, id }) => {
        return {
          label: name,
          value: id,
        };
      })
    : [];
  const optionsPriority =
    jsdMappings &&
    Object.values(jsdMappings.priority).map(({ id, name }) => {
      return {
        label: name,
        value: id,
      };
    });

  const optionsIncidentType =
    jsdMappings &&
    Object.values(jsdMappings.incidentType).map(({ id, name }) => {
      return {
        label: name,
        value: id,
      };
    });

  const getPriorityName = (priority) => {
    const { name } = Object.values(jsdMappings.priority).find(({ id }) => {
      return priority === id;
    });
    return name;
  };

  const getIncidentTypeName = (incidentType) => {
    const res = Object.values(jsdMappings.incidentType).find(({ id }) => {
      return incidentType === id;
    });

    return res.name;
  };

  return (
    <div>
      <ModalTransition>
        {modal && (
          <ModalDialog
            heading="Create an incident announcement"
            onClose={toggleModal}
            width="large"
            actions={[
              { text: 'Create', type: 'submit', form: 'form-create-incident' },
              { text: 'Cancel', onClick: toggleModal },
            ]}>
            <Form onSubmit={handleCreateIssue}>
              {({ formProps }) => (
                <form id="form-create-incident" {...formProps}>
                  <Field
                    name="subject"
                    label="Subject"
                    defaultValue=""
                    isRequired>
                    {({ fieldProps }) => (
                      <Fragment>
                        <TextField
                          {...fieldProps}
                          width="x-large"
                          value={subject}
                          onChange={(newValue) => {
                            return (
                              newValue &&
                              setFormData('subject', newValue.target.value)
                            );
                          }}
                        />
                      </Fragment>
                    )}
                  </Field>
                  <Field label="Body" name="body">
                    {({ fieldProps }) => (
                      <Fragment>
                        <TextArea
                          placeholder="Enter a description"
                          {...fieldProps}
                          resize="auto"
                          value={body}
                          onChange={(newValue) => {
                            return (
                              newValue &&
                              setFormData('body', newValue.target.value)
                            );
                          }}
                        />
                      </Fragment>
                    )}
                  </Field>
                  <WrapperLarge>
                    <Field
                      name="priority"
                      label="Priority"
                      isRequired
                      value={{
                        label: priority && getPriorityName(priority),
                        value: priority,
                      }}
                      validate={(value) => {
                        if (value) {
                          priority !== value &&
                            setFormData('priority', value.value);
                          return undefined;
                        }

                        return 'Please select priority level';
                      }}>
                      {({ fieldProps: { id, ...rest }, error }) => (
                        <Fragment>
                          <Select
                            inputId="select-priority"
                            {...rest}
                            validationState={error ? 'error' : 'default'}
                            placeholder="Select..."
                            isClearable
                            options={optionsPriority}
                          />
                          {error && <ErrorMessage>{error}</ErrorMessage>}
                        </Fragment>
                      )}
                    </Field>
                  </WrapperLarge>
                  <WrapperLarge>
                    <Field
                      name="incidentType"
                      label="Incident Type"
                      isRequired
                      value={{
                        label:
                          incidentType && getIncidentTypeName(incidentType),
                        value: incidentType,
                      }}
                      validate={(value) => {
                        if (value) {
                          incidentType !== value &&
                            setFormData('incidentType', value.value);
                          return undefined;
                        }

                        return 'Please select incidentType';
                      }}>
                      {({ fieldProps: { id, ...rest }, error }) => (
                        <Fragment>
                          <Select
                            inputId="select-incidentType"
                            {...rest}
                            validationState={error ? 'error' : 'default'}
                            placeholder="Select..."
                            isClearable
                            options={optionsIncidentType}
                          />
                          {error && <ErrorMessage>{error}</ErrorMessage>}
                        </Fragment>
                      )}
                    </Field>
                  </WrapperLarge>
                  <Field
                    name="impactedServices"
                    label="Impacted Services"
                    defaultValue=""
                    isRequired>
                    {({ fieldProps }) => (
                      <Fragment>
                        <TextField
                          {...fieldProps}
                          width="x-large"
                          value={impactedServices}
                          onChange={(newValue) => {
                            return (
                              newValue &&
                              setFormData(
                                'impactedServices',
                                newValue.target.value
                              )
                            );
                          }}
                        />
                      </Fragment>
                    )}
                  </Field>
                  <WrapperLarge>
                    <Field
                      name="organizations"
                      label="Organization"
                      isRequired
                      value={organizations}
                      validate={(value) => {
                        if (Array.isArray(value) && value.length) {
                          setFormData('organizations', value);
                          return undefined;
                        }

                        return 'Please select organizations';
                      }}>
                      {({ fieldProps: { id, ...rest }, error }) => (
                        <Fragment>
                          <Select
                            inputId="select-incident-organizations"
                            {...rest}
                            validationState={error ? 'error' : 'default'}
                            placeholder="Select..."
                            isMulti
                            isClearable
                            options={optionsOrg}
                          />
                          {error && <ErrorMessage>{error}</ErrorMessage>}
                        </Fragment>
                      )}
                    </Field>
                  </WrapperLarge>
                </form>
              )}
            </Form>
          </ModalDialog>
        )}
      </ModalTransition>
    </div>
  );
};

IncidentForm.propTypes = {
  getQueueIssues: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  issue: state.issue,
  jira: state.jira,
  manage: state.manage,
});

export default connect(mapStateToProps, {
  getQueueIssues,
  setFormData,
  showMarkedValues,
  createIncidentRequest,
  getJsdMappings,
  getDbOrganizations,
})(IncidentForm);
