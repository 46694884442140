import axios from 'axios';
import { setAlert } from './alert';
import {
  CREATE_PROVIDER,
  DELETE_PROVIDER,
  GET_PROVIDERS,
  POST_ERROR,
  SET_LOADING_SELECT,
  SET_PROVIDER_FORM_DATA,
  UPDATE_PROVIDER,
} from './types';

const _config = window._env_;

axios.defaults.withCredentials = true;

export const getProviders = () => async (dispatch) => {
  try {
    const res = await axios.get(`${_config.MAT_URL}/api/1/provider`);
    dispatch({
      type: GET_PROVIDERS,
      payload: res.data,
    });
  } catch ({ message }) {
    console.log(message)
    dispatch({
      type: POST_ERROR,
      payload: { message },
    });
  }
};

export const createProvider = (payload) => async (dispatch) => {
  try {
    dispatch({
      type: SET_LOADING_SELECT,
      payload: true,
    });
    const res = await axios.post(`${_config.MAT_URL}/api/1/provider`, payload);
    dispatch({
      type: CREATE_PROVIDER,
      payload: res.data,
    });
    dispatch(
      setAlert({
        msg: `${payload.name} created`,
        alertType: 'success',
        description: 'Record will be saved locally.',
      })
    );
  } catch (err) {
    dispatch({
      type: POST_ERROR,
      payload: { err },
    });
    dispatch(
      setAlert({
        msg: `User ${payload.name} creation failed`,
        alertType: 'error',
      })
    );
    dispatch({
      type: SET_LOADING_SELECT,
      payload: false,
    });
  }
};

export const setProviderFormData = (field, value) => async (dispatch) => {
  console.log(field, value)
  dispatch({
    type: SET_PROVIDER_FORM_DATA,
    payload: { field, value },
  });
};

export const removeProvider = (payload) => async (dispatch) => {
  try {
    const res = await axios.delete(
      `${_config.MAT_URL}/api/1/provider/delete/${payload._id}`
    );
    dispatch({
      type: DELETE_PROVIDER,
      payload: res.data,
    });
    dispatch(
      setAlert({
        msg: `${payload.name} deleted`,
        alertType: 'success',
        description: 'Record will only be deleted locally.',
      })
    );
  } catch (err) {
    dispatch({
      type: POST_ERROR,
      payload: { err },
    });
    dispatch(
      setAlert({
        msg: `User ${payload.name} deletion failed`,
        alertType: 'error',
      })
    );
    console.log(JSON.stringify(payload));
  }
};

export const updateProvider = (payload) => async (dispatch) => {
  try {
    const res = await axios.post(
      `${_config.MAT_URL}/api/1/provider/update`,
      payload
    );
    dispatch({
      type: UPDATE_PROVIDER,
      payload: res.data,
    });
    dispatch(
      setAlert({
        msg: `${res.data.name} updated`,
        alertType: 'success',
        description: 'Record will be saved locally.',
      })
    );
  } catch (err) {
    dispatch({
      type: POST_ERROR,
      payload: { err },
    });
  }
};


