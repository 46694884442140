import React from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Button from '@atlaskit/button';
import { useCookies } from 'react-cookie';

const config = window._env_;

const Landing = ({ isAuthenticated }) => {
  const [cookies, setCookie] = useCookies(['token']);
  if (isAuthenticated && cookies.token) {
    return <Redirect to="/dashboard" />;
  }

  const connectJira = () => {
    window.location.replace(`${config.MAT_URL}/sessions/connect`);
  };
  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-6 d-flex justify-content-center login vh-100">
          <div className="parent mt-5">
            <img src="/intercloud.png" alt="" />
          </div>
        </div>
        <div className="col-md-6 text-right">
          <div className="row h-25"></div>

          <div className="row h-25">
            <div className="col-md-10 text-right">
              <h1>Announcement</h1>
              <h1> Console</h1>
            </div>
            <div className="col-md-2 text-right"></div>
          </div>
          <div className="row">
            <div className="col-md-6"></div>
            <div className="col-md-4">
              <Button
                shouldFitContainer
                appearance="primary"
                onClick={connectJira}>
                Connect via Jira
              </Button>
            </div>
            <div className="col-md-2"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

Landing.propTypes = {
  isAuthenticated: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.jsd.isAuthenticated,
});

export default connect(mapStateToProps)(Landing);
