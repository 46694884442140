import {
  GET_CLOUD_ID,
  GET_ALL_TICKETS,
  GET_ALL_PROJECTS,
  GET_GROUPS,
  POST_ERROR,
  LOGOUT,
} from '../actions/types';

const initialState = {
  // token: localStorage.getItem('token'),
  isAuthenticated: null,
  loading: true,
  error: {},
  cloudId: null,
  projects: {},
  groups: {},
  issues: [],
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_CLOUD_ID:
      return {
        ...state,
        cloudId: payload[0].id,
        isAuthenticated: true,
        loading: false,
      };
    case GET_ALL_TICKETS:
      return {
        ...state,
        issues: payload,
        loading: false,
      };
    case GET_ALL_PROJECTS:
      return {
        ...state,
        projects: payload,
        loading: false,
      };
    case GET_GROUPS:
      return {
        ...state,
        groups: payload,
        loading: false,
      };
    case LOGOUT:
    case POST_ERROR:
      return {
        ...state,
        error: payload,
        loading: false,
      };
    default:
      return state;
  }
}
