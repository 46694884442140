import React, { Fragment, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Navbar, NavbarBrand, Button } from 'reactstrap';
import DropdownMenu, {
  DropdownItem,
  DropdownItemGroup,
} from '@atlaskit/dropdown-menu';
import { FaSignOutAlt, FaUpload, FaTasks, FaUserAlt } from 'react-icons/fa';
import { FcOrganization } from 'react-icons/fc';
import { FiUser } from 'react-icons/fi/index';
import { Redirect, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { checkSession } from '../../actions/jsd';
import { useCookies } from 'react-cookie';
import { logoutJira } from '../../actions/jsd';

const Navigation = ({
  history,
  isAuthenticated,
  loading,
  logoutJira,
  checkSession,
  isAdmin,
  isSiteAdmin,
  isStandard,
}) => {
  useEffect(() => {
    checkSession();
  }, []);

  const [cookies, setCookie] = useCookies(['token']);

  const handleLogout = () => {
    logoutJira();
  };

  const authLinks = (
    <div>
      <Navbar color="primary" dark expand="md" className="py-0">
        <div className="row align-items-center">
          <div className="col-md-2">
            <NavbarBrand href="/dashboard" className="mr-auto">
              <img
                src="/intercloud.png"
                className="img-fluid py-1"
                alt=""
              />
            </NavbarBrand>
          </div>
          <div className="col-md-2"></div>
          <div className="col-md-4">
            <div className="d-flex text-white justify-content-center my-auto h4">
              Announcement Console
            </div>
          </div>
          <div className="col-md-4 d-flex justify-content-end">
            <div className="mr-3">
              {(isStandard || isSiteAdmin) && (
                <DropdownMenu
                trigger={<Button color="primary">Settings</Button>}
                position="bottom right">
                  <DropdownItemGroup>
                    <DropdownItem
                      className="btn-hover"
                      onClick={() => {
                        history.push('/manage');
                      }}>
                      <span>
                        <FaTasks className="mr-2" />
                        MANAGE RECIPIENTS
                      </span>
                    </DropdownItem>
                  </DropdownItemGroup>
                </DropdownMenu>
              )}
              {isAdmin && (
                <DropdownMenu
                  trigger={<Button color="primary">Settings</Button>}
                  position="bottom right">
                  <DropdownItemGroup>
                    <DropdownItem
                      className="btn-hover"
                      onClick={() => {
                        history.push('/upload');
                      }}>
                      <span>
                        <FaUpload className="mr-2" />
                        UPLOAD RECIPIENTS FILE
                      </span>
                    </DropdownItem>
                    <DropdownItem
                      className="btn-hover"
                      onClick={() => {
                        history.push('/manage');
                      }}>
                      <span>
                        <FaTasks className="mr-2" />
                        MANAGE RECIPIENTS
                      </span>
                    </DropdownItem>
                    <DropdownItem
                      className="btn-hover"
                      onClick={() => {
                        history.push('/organization');
                      }}>
                      <span>
                        <FcOrganization className="mr-2" />
                        MANAGE ORGANIZATIONS
                      </span>
                    </DropdownItem>
                    <DropdownItem
                      className="btn-hover"
                      onClick={() => {
                        history.push('/user');
                      }}>
                      <span>
                        <FiUser className="mr-2" />
                        MANAGE USERS
                      </span>
                    </DropdownItem>
                    <DropdownItem
                      className="btn-hover"
                      onClick={() => {
                        history.push('/provider');
                      }}>
                      <span>
                        <FaUserAlt className="mr-2" />
                        MANAGE PROVIDERS
                      </span>
                    </DropdownItem>
                  </DropdownItemGroup>
                </DropdownMenu>
              )}
            </div>
            <div>
              <Button id="btnLogout" color="primary" onClick={handleLogout}>
                {' '}
                <FaSignOutAlt />
              </Button>
            </div>
          </div>
        </div>
      </Navbar>
    </div>
  );

  // console.log(`isAuthenticated: ${isAuthenticated}, token: ${token}`)
  return (
    <Fragment>
      {isAuthenticated || cookies.token ? authLinks : <Redirect to="/" />}
      {/* { authLinks } */}
    </Fragment>
  );
};

Navbar.propTypes = {
  logoutJira: PropTypes.func,
  isAuthenticated: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.servicedesk.isAuthenticated,
  authUrl: state.jira.authUrl,
  loading: state.servicedesk.loading,
  isAdmin: state.jsd.isAdmin,
  isSiteAdmin: state.jsd.isSiteAdmin,
  isStandard: state.jsd.isStandard,
});

export default withRouter(
  connect(mapStateToProps, { logoutJira, checkSession })(Navigation)
);
