import axios from 'axios';
import { setAlert } from './alert';
import {
  ADD_USER,
  DELETE_USER,
  GET_USERS,
  POST_ERROR,
  SET_LOADING_SELECT,
  SET_USER_FORM_DATA,
  UPDATE_USER,
} from './types';

const _config = window._env_;

axios.defaults.withCredentials = true;

export const getUsers = () => async (dispatch) => {
  try {
    const res = await axios.get(`${_config.MAT_URL}/api/1/user`);
    dispatch({
      type: GET_USERS,
      payload: res.data,
    });
  } catch ({ message }) {
    console.log(message)
    dispatch({
      type: POST_ERROR,
      payload: { message },
    });
  }
};

export const createUser = (payload) => async (dispatch) => {
  try {
    dispatch({
      type: SET_LOADING_SELECT,
      payload: true,
    });
    const res = await axios.post(`${_config.MAT_URL}/api/1/user`, payload);
    dispatch({
      type: ADD_USER,
      payload: res.data,
    });
    dispatch(
      setAlert({
        msg: `${payload.name} created`,
        alertType: 'success',
        description: 'Record will be saved locally.',
      })
    );
  } catch (err) {
    dispatch({
      type: POST_ERROR,
      payload: { err },
    });
    dispatch(
      setAlert({
        msg: `User ${payload.name} creation failed`,
        alertType: 'error',
      })
    );
    dispatch({
      type: SET_LOADING_SELECT,
      payload: false,
    });
  }
};

export const setUserFormData = (field, value) => async (dispatch) => {
  console.log(field, value)
  dispatch({
    type: SET_USER_FORM_DATA,
    payload: { field, value },
  });
};

export const removeUser = (payload) => async (dispatch) => {
  try {
    const res = await axios.delete(
      `${_config.MAT_URL}/api/1/user/delete/${payload._id}`
    );
    dispatch({
      type: DELETE_USER,
      payload: res.data,
    });
    dispatch(
      setAlert({
        msg: `${payload.name} deleted`,
        alertType: 'success',
        description: 'Record will only be deleted locally.',
      })
    );
  } catch (err) {
    dispatch({
      type: POST_ERROR,
      payload: { err },
    });
    dispatch(
      setAlert({
        msg: `User ${payload.name} deletion failed`,
        alertType: 'error',
      })
    );
    console.log(JSON.stringify(payload));
  }
};

export const updateUser = (payload) => async (dispatch) => {
  try {
    const res = await axios.post(
      `${_config.MAT_URL}/api/1/user/update`,
      payload
    );
    dispatch({
      type: UPDATE_USER,
      payload: res.data,
    });
    dispatch(
      setAlert({
        msg: `${res.data.name} updated`,
        alertType: 'success',
        description: 'Record will be saved locally.',
      })
    );
  } catch (err) {
    dispatch({
      type: POST_ERROR,
      payload: { err },
    });
  }
};


