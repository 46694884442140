import axios from 'axios';
import asyncPool from 'tiny-async-pool';
import FileDownload from 'js-file-download';
import {
  GET_RECIPIENTS,
  POST_ERROR,
  GET_CSV_FILES,
  UPLOAD_FILE,
  IMPORT_CSV_FILE,
  GET_DB_CUSTOMERS,
  SET_LOADING,
  SET_JSD_CUSTOMERS,
  SET_LOADING_BUTTON,
  SET_EDIT_DATA,
  SET_RECIPIENT_FORM_DATA,
  UPDATE_USER,
  ADD_RECIPIENT,
  GET_DB_PROVIDERS,
  CREATE_PROVIDER,
  SET_LOADING_SELECT,
  UPDATE_NOTIF,
  UPDATE_NOTIF_MAIN,
  UPDATE_NOTIF_CHANGE,
  DELETE_CUSTOMER,
  CREATE_DB_ORGANIZATION_RESPONSE,
  GET_DB_ORGANIZATION_RESPONSE,
  SET_JSD_MAPPINGS,
  SET_OPTIONS_ORGS,
  SET_SEARCH_INPUT,
  DELETE_ORGANIZATION
} from './types';
import { setAlert } from './alert';

// axios.defaults.withCredentials = true;
// axios.defaults.xsrfHeaderName = "X-CSRFTOKEN";
// axios.defaults.xsrfCookieName = "csrftoken";
axios.defaults.withCredentials = true;

const _config = window._env_;

export const uploadFile = (filename, formData) => async (dispatch) => {
  const config = {
    headers: { 'Content-Type': 'multipart/form-data' },
    // params: { filename },
  };
  try {
    const res = await axios.post(
      `${_config.MAT_URL}/api/1/customer/upload`,
      formData,
      config
    );
    dispatch({
      type: UPLOAD_FILE,
      payload: res.data,
    });

    dispatch(
      setAlert({
        msg: `${filename} uploaded successfully`,
        alertType: 'success',
      })
    );
  } catch ({ response: { data, status } = {} }) {
    dispatch({
      type: POST_ERROR,
      payload: { msg: data, status },
    });
    dispatch(
      setAlert({ msg: `${filename} failed to upload`, alertType: 'error' })
    );
  }
};

export const getRecipients = () => async (dispatch) => {
  try {
    const res = await axios.get(`${_config.MAT_URL}/api/1/customer`);

    dispatch({
      type: GET_RECIPIENTS,
      payload: res.data,
    });
  } catch ({ message }) {
    dispatch({
      type: POST_ERROR,
      payload: { message },
    });
  }
};

export const getCsvFiles = () => async (dispatch) => {
  try {
    const res = await axios.get(`${_config.MAT_URL}/api/1/customer/csv`);
    dispatch({
      type: GET_CSV_FILES,
      payload: res.data,
    });
  } catch ({ message }) {
    dispatch({
      type: POST_ERROR,
      payload: { message },
    });
  }
};

export const importCsvFile = (filename) => async (dispatch) => {
  const payload = { filename };
  try {
    const res = await axios.post(
      `${_config.MAT_URL}/api/1/customer/csv`,
      payload
    );
    dispatch({
      type: IMPORT_CSV_FILE,
      payload: res.data,
    });
    dispatch(
      setAlert({
        msg: `CSV file ${filename} was successfully imported`,
        alertType: 'success',
      })
    );
  } catch (err) {
    dispatch({
      type: POST_ERROR,
      payload: { err },
    });
    dispatch(
      setAlert({
        msg: `CSV file ${filename} import failed`,
        alertType: 'error',
      })
    );
  }
};

export const getDbCustomers = () => async (dispatch) => {
  try {
    const res = await axios.get(`${_config.MAT_URL}/api/1/customer/orgs`);
    dispatch({
      type: GET_DB_CUSTOMERS,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: POST_ERROR,
      payload: { err },
    });
  }
};

export const updateCustomer = async (payload) => {
  try {
    return await axios.post(
      `${_config.MAT_URL}/api/1/customer/update`,
      payload
    );
  } catch (err) {
    console.log(err);
  }
};

export const updateCustomersByName = async (payload) => {
  try {
    return await axios.post(
      `${_config.MAT_URL}/api/1/customer/update-all-by-name`,
      payload
    );
  } catch (err) {
    console.log(err);
  }
};

export const updateCustomersByCustomerId = async (payload) => {
  try {
    return await axios.post(
      `${_config.MAT_URL}/api/1/customer/update-by-customerId`,
      payload
    );
  } catch (err) {
    console.log(err);
  }
};

export const updateCustomerByName = async (payload) => {
  try {
    return await axios.patch(
      `${_config.MAT_URL}/api/1/customer/update-by-name`,
      payload
    );
  } catch (err) {
    console.log(err);
  }
};

export const updateDbCustOrganization = async (payload) => {
  try {
    return await axios.post(
      `${_config.MAT_URL}/api/1/customer/update-org`,
      payload
    );
  } catch (err) {
    console.log(err);
  }
};

export const updateDbOrganisation = async (payload) => {
  try {
    return await axios.patch(`${_config.MAT_URL}/api/1/organization`, payload);
  } catch (err) {
    console.log(err);
  }
};

export const setLoadingButton = (isLoading) => async (dispatch) => {
  dispatch({
    type: SET_LOADING_BUTTON,
    payload: isLoading,
  });
};

export const saveJsdCustomers = (payload) => async (dispatch) => {
  dispatch({
    type: SET_JSD_CUSTOMERS,
    payload: payload,
  });
};

export const getOrgUsers = async (id) => {
  try {
    let resultsArray = [];
    let lastPage = false;
    let start = 0;

    do {
      const res = await axios.get(`${_config.MAT_URL}/org-users`, {
        params: { start, id },
      });
      const { size, isLastPage, values } = res.data;
      lastPage = isLastPage;
      resultsArray.push(values);
      start = start + size;
    } while (!lastPage);

    const mergedResults = resultsArray.flat();

    return mergedResults;
  } catch (err) {
    console.log(err);
  }
};

export const getUsersPerOrg = () => async (dispatch) => {
  try {
    let resultsArray = [];
    let lastPage = false;
    let start = 0;

    do {
      const res = await axios.get(`${_config.MAT_URL}/api/1/jsd/organization`, {
        params: { start },
      });
      const { size, isLastPage, values } = res.data;
      lastPage = isLastPage;
      resultsArray.push(values);
      start = start + size;
    } while (!lastPage);

    const mergedResults = resultsArray.flat();
    const promiseOrgUser = (id) =>
      new Promise((resolve) =>
        getOrgUsers(id).then((result) => {
          resolve(result);
        })
      );
    asyncPool(
      50,
      mergedResults.map(({ id }) => {
        return id;
      }),
      promiseOrgUser
    ).then((results) => {
      let customers = [];
      results.forEach((result) => {
        customers.push(result);
      });

      customers = customers.length && customers
        .flat()
        .map(
          ({
            accountId,
            emailAddress,
            displayName,
            _links: { avatarUrls },
          }) => {
            return { accountId, emailAddress, displayName, avatarUrls };
          }
        );

      dispatch({
        type: SET_JSD_CUSTOMERS,
        payload: customers,
      });
      dispatch({
        type: SET_LOADING,
        payload: false,
      });
    });
  } catch (err) {
    dispatch({
      type: POST_ERROR,
      payload: { err },
    });
    dispatch({
      type: SET_LOADING,
      payload: false,
    });
  }
};

export const setEditData = (field, value) => async (dispatch) => {
  dispatch({
    type: SET_EDIT_DATA,
    payload: { field, value },
  });
};

export const setRecipientFormData = (field, value) => async (dispatch) => {
  dispatch({
    type: SET_RECIPIENT_FORM_DATA,
    payload: { field, value },
  });
};

export const setDeleteData = (field, value) => async (dispatch) => {
  dispatch({
    type: DELETE_CUSTOMER,
    payload: { field, value },
  });
};

export const updateUser = (payload) => async (dispatch) => {
  try {
    const res = await axios.post(
      `${_config.MAT_URL}/api/1/customer/update-by-id`,
      payload
    );
    dispatch({
      type: UPDATE_USER,
      payload: res.data,
    });
    dispatch(
      setAlert({
        msg: `${res.data.name} updated`,
        alertType: 'success',
        description: 'Record will be saved locally.',
      })
    );
  } catch (err) {
    dispatch({
      type: POST_ERROR,
      payload: { err },
    });
  }
};

export const getProviders = () => async (dispatch) => {
  try {
    const res = await axios.get(`${_config.MAT_URL}/api/1/provider`);

    dispatch({
      type: GET_DB_PROVIDERS,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: POST_ERROR,
      payload: { err },
    });
  }
};

export const createProvider = (payload) => async (dispatch) => {
  try {
    dispatch({
      type: SET_LOADING_SELECT,
      payload: true,
    });
    const res = await axios.post(`${_config.MAT_URL}/api/1/provider`, payload);

    dispatch({
      type: CREATE_PROVIDER,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: POST_ERROR,
      payload: { err },
    });
    dispatch({
      type: SET_LOADING_SELECT,
      payload: false,
    });
  }
};

export const updateNotif = (payload) => async (dispatch) => {
  try {
    const res = await axios.patch(
      `${_config.MAT_URL}/api/1/customer/notif`,
      payload
    );
    dispatch({
      type: UPDATE_NOTIF,
      payload: res.data,
    });

    dispatch(setAlert({ msg: 'Notification updated', alertType: 'success' }));
  } catch (err) {
    dispatch(
      setAlert({ msg: 'Notification update failed', alertType: 'error' })
    );
    dispatch({
      type: POST_ERROR,
      payload: { err },
    });
  }
};

export const deleteCustomer = (payload) => async (dispatch) => {
  console.log('deleteCustomer', payload);
  try {
    const res = await axios.post(
      `${_config.MAT_URL}/api/1/customer/deleteCustomer`,
      payload
    );
    dispatch({
      type: DELETE_CUSTOMER,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: POST_ERROR,
      payload: { err },
    });
  }
};

export const addRecipient = (payload) => async (dispatch) => {
  try {
    dispatch({
      type: SET_LOADING_SELECT,
      payload: true,
    });
    const res = await axios.post(`${_config.MAT_URL}/api/1/customer`, payload);
    dispatch({
      type: ADD_RECIPIENT,
      payload: res.data,
    });
    dispatch(
      setAlert({
        msg: `${payload.name} created`,
        alertType: 'success',
        description: 'Record will be saved locally. Perform a sync to update.',
      })
    );
  } catch (err) {
    dispatch({
      type: POST_ERROR,
      payload: { err },
    });
    dispatch(
      setAlert({
        msg: `User ${payload.name} creation failed`,
        alertType: 'error',
      })
    );
    dispatch({
      type: SET_LOADING_SELECT,
      payload: false,
    });
  }
};

export const removeRecipient = (payload) => async (dispatch) => {
  try {
    const res = await axios.delete(
      `${_config.MAT_URL}/api/1/customer/delete/${payload._id._id}`
    );
    dispatch({
      type: DELETE_CUSTOMER,
      payload: res.data,
    });
    dispatch(
      setAlert({
        msg: `${payload._id.name} deleted`,
        alertType: 'success',
        description: 'Record will only be deleted locally.',
      })
    );
  } catch (err) {
    dispatch({
      type: POST_ERROR,
      payload: { err },
    });
    dispatch(
      setAlert({
        msg: `User ${payload._id.name} deletion failed`,
        alertType: 'error',
      })
    );
    console.log(JSON.stringify(payload));
  }
};

export const removeDbOrganization = (payload) => async (dispatch) => {
  try {
    const res = await axios.delete(
      `${_config.MAT_URL}/api/1/organization/delete/${payload._id}`
    );
    dispatch({
      type: DELETE_ORGANIZATION,
      payload: res.data,
    });
    dispatch(
      setAlert({
        msg: `${payload.name} deleted`,
        alertType: 'success',
        description: 'Record will only be deleted locally.',
      })
    );
  } catch (err) {
    dispatch({
      type: POST_ERROR,
      payload: { err },
    });
    dispatch(
      setAlert({
        msg: `Organization ${payload.name} deletion failed`,
        alertType: 'error',
      })
    );
    console.log(JSON.stringify(payload));
  }
};

export const downloadFile = (filename) => async (dispatch) => {
  try {
    const res = await axios.get(
      `${_config.MAT_URL}/api/1/customer/download/${filename}`
    );
    FileDownload(res.data, filename);
  } catch (err) {
    dispatch({
      type: POST_ERROR,
      payload: { err },
    });
  }
};

export const getJsdMappings = () => async (dispatch) => {
  try {
    const res = await axios.get(`${_config.MAT_URL}/api/1/jsdmapping`);
    dispatch({
      type: SET_JSD_MAPPINGS,
      payload: res.data,
    });

    // localStorage.setItem('jsdMappings', JSON.stringify(res.data));
  } catch (err) {
    dispatch({
      type: POST_ERROR,
      payload: { err },
    });
  }
};

export const createDbOrganization = (payload) => async (dispatch) => {
  try {
    let res = await axios.post(
      `${_config.MAT_URL}/api/1/organization`,
      payload
    );

    dispatch({ type: CREATE_DB_ORGANIZATION_RESPONSE, payload: res });
    dispatch(
      setAlert({
        msg: `Organization ${res.data.name} was created successfully`,
        alertType: 'success',
        description: 'Record will only be saved locally.',
      })
    );
  } catch ({ response: { data, status } = {} }) {
    dispatch({
      type: POST_ERROR,
      payload: { msg: data, status },
    });
    dispatch(setAlert({ msg: `Creation failed`, alertType: 'error' }));
  }
};

export const getDbOrganizations = (payload) => async (dispatch) => {
  try {
    let res = await axios.get(`${_config.MAT_URL}/api/1/organization`, payload);

    const optionsOrg = res.data.map(({ name, index }) => {
      return {
        label: name,
        value: index,
      };
    });

    dispatch({ type: GET_DB_ORGANIZATION_RESPONSE, payload: res });
    dispatch({ type: SET_OPTIONS_ORGS, payload: optionsOrg });
  } catch ({ response: { data, status } = {} }) {
    dispatch({
      type: POST_ERROR,
      payload: { msg: data, status },
    });
  }
};

export const setSearchInput = (payload) => async (dispatch) => {
  dispatch({ type: SET_SEARCH_INPUT, payload });
};
