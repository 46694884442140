import {
  GET_QUEUE_ISSUES,
  GET_REQUEST_BY_ID,
  SET_LOADING,
  SET_LOADING_MODAL,
  SET_JSD_QUEUE_SIZE,
  GET_JSD_ORGANIZATIONS,
  LOGOUT,
  GET_INFO,
  SET_CHECK_SESSION,
  AUTH_ERROR,
} from '../actions/types';

const initialState = {
  responseData: null,
  isAuthenticated: false,
  queue: null,
  loading: true,
  loadingModal: true,
  request: null,
  queueSize: 0,
  organizations: [],
  isAdmin: false,
  isSiteAdmin: false,
  isStandard: false,
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SET_LOADING:
      return {
        ...state,
        loading: payload,
      };
    case SET_LOADING_MODAL:
      return {
        ...state,
        loadingModal: payload,
      };
    case GET_QUEUE_ISSUES:
      return {
        ...state,
        queue: payload,
        loading: false,
      };
    case GET_REQUEST_BY_ID:
      return {
        ...state,
        request: payload,
        loadingModal: false,
      };
    case SET_JSD_QUEUE_SIZE:
      return {
        ...state,
        queueSize: payload,
      };
    case GET_JSD_ORGANIZATIONS:
      return {
        ...state,
        organizations: payload,
        // loading: false
      };
    case LOGOUT:
      return {
        ...state,
        isAuthenticated: false,
      };

    case SET_CHECK_SESSION:
      return {
        ...state,
        isAdmin: payload.isAdmin,
        isSiteAdmin: payload.isSiteAdmin,
        isStandard: payload.isStandard,
        responseData: payload,
        isAuthenticated: true,
      };
    case AUTH_ERROR:
      return {
        ...state,
        isAuthenticated: false,
      };
    default:
      return state;
  }
}
