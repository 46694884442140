import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  getDbOrganizations,
  removeDbOrganization,
  createDbOrganization,
  updateDbOrganisation,
  getUsersPerOrg,
  getRecipients,
  updateCustomersByCustomerId,
  getOrgUsers
} from '../../actions/manage';

import { setAlert } from '../../actions/alert';
import { organizationTableFormatter } from '../../utils';

import Tooltip from '@atlaskit/tooltip';
import ArrowLeftCircleIcon from '@atlaskit/icon/glyph/arrow-left-circle';
import { setLoading as setLoadingMain } from '../../actions/servicedesk';
import TrashIcon from '@atlaskit/icon/glyph/trash';
import DynamicTableStateless from '@atlaskit/dynamic-table';
import styled from 'styled-components';
import EditIcon from '@atlaskit/icon/glyph/edit';
import DownloadIcon from '@atlaskit/icon/glyph/download';
import Button, { ButtonGroup, LoadingButton } from '@atlaskit/button';
import OrganizationAdd from './OrganizationAdd';
import { setOrganizationFormData, updateOrganization } from '../../actions/organization';
import { getOrganizations } from '../../actions/jsd';

const Wrapper = styled.div`
  min-width: 600px;
`;

const Organization = ({
  history,
  manage: {
    dbOrganizations,
    deleteOrganization,
    jsdCustomers,
    recipients
  },
  organization: {
    addOrganizationData,
    addOrganization,
    updateOrganization: updatedOrganization,
  },
  servicedesk: { loading: loadingMain },
  setLoadingMain,
  getDbOrganizations,
  removeDbOrganization,
  createDbOrganization,
  setOrganizationFormData,
  updateOrganization,
  setAlert,
  jsd: { organizations },
  getOrganizations,
  getUsersPerOrg,
  getRecipients
}) => {
  const [modaladd, setModalAdd] = useState(false);

  useEffect(() => {
    dbOrganizations.length && setLoadingMain(false);
  }, [getDbOrganizations, dbOrganizations, setLoadingMain]);

  useEffect(() => {
    getDbOrganizations();
    getOrganizations();
  }, []);

  useEffect(() => {
    deleteOrganization && getDbOrganizations();
    addOrganization && getDbOrganizations();
    updatedOrganization && getDbOrganizations();
    !organizations.length && getOrganizations();
    !jsdCustomers.length && getUsersPerOrg();
    !recipients.length && getRecipients();
  }, [deleteOrganization, addOrganization, updatedOrganization, getUsersPerOrg, recipients, getRecipients]);

  const handleDelete = (id) => {
    removeDbOrganization(id);
  };

  const toggleAdd = () => {
    setOrganizationFormData('name', '');
    setModalAdd(!modaladd);
  };

  const handleEdit = ({
    _id,
    name,
  }) => {
    setOrganizationFormData('_id', _id);
    setOrganizationFormData('name', name);
    setModalAdd(!modaladd);
  };

  const actionFormatter = (org) => {
    return (
      <div className="d-inline-flex align-items-center">
        <Tooltip content="Edit">
          <a
            id={`edit-${org._id}`}
            className="anchor-action-edit"
            href="#"
            onClick={(e) => {
              e.preventDefault();
              handleEdit(org);
            }}>
            <EditIcon size="medium" />
          </a>
        </Tooltip>
        <Tooltip content="Sync from JIRA">
          <a
            id={`sync-${org._id}`}
            className="anchor-action-edit"
            href="#foo"
            onClick={(e) => {
              e.preventDefault();
              handleSync(org);
            }}>
            <DownloadIcon size="medium" />
          </a>
        </Tooltip>
        <Tooltip content="Delete">
          <a
            id={`delete-${org._id}`}
            className="anchor-action-delete"
            href="#"
            onClick={(e) => {
              e.preventDefault();
              handleDelete(org);
            }}>
            <TrashIcon size="medium" />
          </a>
        </Tooltip>
      </div>
    );
  };

  const createHead = (withWidth) => {
    return {
      cells: [
        {
          key: 'id',
          content: 'Id',
          isSortable: true,
          width: withWidth ? 10 : undefined,
        },
        {
          key: 'name',
          content: 'Name',
          isSortable: true,
          width: withWidth ? 10 : undefined,
        },
        {
          key: 'customerCount',
          content: 'No. of Customer',
          isSortable: true,
          width: withWidth ? 20 : undefined,
        },
        {
          key: '_links',
          content: 'Links',
          isSortable: true,
          width: withWidth ? 70 : undefined,
        },
        {
          key: '_id',
          content: 'Action',
          width: withWidth ? 10 : undefined,
        },
      ],
    };
  };
  const head = createHead(true);

  const rows = dbOrganizations.map((org) => ({
    key: `row-${org._id}`,
    cells: [
      {
        key: org.id,
        content: (org.id && organizationTableFormatter(org, org.id)) || '--',
      },
      {
        key: org.name,
        content: organizationTableFormatter(org, org.name),
      },
      {
        key: org.customerCount,
        content: organizationTableFormatter(org, org.customerCount),
      },
      {
        key: org._links,
        content: (org._links?.self && organizationTableFormatter(org, org._links?.self, true)) || '--',
      },
      {
        content: actionFormatter(org),
      },
    ]
  }));

  const handleAddOrganization = () => {
    addOrganizationData.name = addOrganizationData.name.trim();
    if (addOrganizationData._id) {
      updateOrganization(addOrganizationData);
    } else {
      createDbOrganization(addOrganizationData);
    }
    toggleAdd();
  };

  const handleSyncOrg = async () => {
    setLoadingMain(true);
    const customersPromises = [];
    const orgPromises = [];
    for (const organization of organizations) {
      const { id, name, _links } = organization
      const customers = await getOrgUsers(+id) || [];
      customers.forEach(customer => {
        const { accountId, displayName, _links, emailAddress } = customer;
        customersPromises.push(
          new Promise((resolve) => {
            updateCustomersByCustomerId({
              customerId: accountId,
              name: displayName,
              email: emailAddress,
              avatarUrls: _links?.avatarUrls,
              organization: name,
              organizationId: +id
            }).then((res) => {
              resolve(res);
            });
          })
        );
      });
      orgPromises.push(
        new Promise((resolve) => {
          updateDbOrganisation({ id, name, _links, customerCount: customers.length }).then((res) => resolve(res));
        })
      )
    }
    const res = await Promise.all(orgPromises);
    await Promise.all(customersPromises);
    setAlert({
      msg: `${res.length} organization(s) updated`,
      alertType: 'success',
      description:
        'Records has been found from JIRA service desk and will be synced locally.',
    });
    setLoadingMain(false);
    getDbOrganizations();
  };

  const handleSync = async ({id, name, _links}) => {
    setLoadingMain(true);
    const organization = organizations.find(({ id: orgId }) => {
      return +orgId === id;
    });
    if (organization) {
      const customers = await getOrgUsers(id);
      const promise1 = customers.map(
        (customer) => {
          const { accountId, displayName, _links } = customer;
          return new Promise((resolve) => {
            updateCustomersByCustomerId({
              customerId: accountId,
              name: displayName,
              avatarUrls: _links?.avatarUrls,
              organization: name,
              organizationId: id
            }).then((res) => {
              resolve(res);
            });
          });
        }
      );
      await Promise.all([
        promise1,
        new Promise((resolve) => {
          updateDbOrganisation({ id, name, _links, customerCount: customers.length }).then((res) => resolve(res));
        })
      ]);
      
      setAlert({
        msg: `Organization ${name} updated`,
        alertType: 'success',
        description:
          'Record has been found from JIRA service desk and will be synced locally.',
      });
    } else {
      setAlert({
        msg: `Organization ${name} not found`,
        alertType: 'warning',
        description: 'Record does not exist on JIRA service desk',
      });
    }
    setLoadingMain(false);
    getDbOrganizations();
  }

  return (
    <Fragment>
      <div className="container-fluid main-bg min-vh-100">
        <div className="container-fluid py-3">
          <div className="container upload-panel inner-bg">
            <div className="m-4">
              <div className="row">
                <div className="col-md-2">
                  <Tooltip>
                    <Button
                      iconBefore={
                        <ArrowLeftCircleIcon
                          size="small"
                          label="Go back to dashboard"
                        />
                      }
                      onClick={() => history.push('/dashboard')}>
                      BACK
                    </Button>
                  </Tooltip>
                </div>
                <div className="col-md-6"></div>
                <div className="col-md-4">
                  <div className="d-flex justify-content-end">
                    <ButtonGroup>
                      <LoadingButton
                        appearance="primary"
                        isLoading={loadingMain}
                        onClick={handleSyncOrg}>
                        Sync from Jira
                      </LoadingButton>
                      <Button onClick={toggleAdd}>Add Organization</Button>
                    </ButtonGroup>
                    <OrganizationAdd
                      modaladd={modaladd}
                      toggleAdd={toggleAdd}
                      addOrganization={handleAddOrganization}
                    />
                  </div>
                </div>
              </div>
              <div className="mt-3">
                <h3>Organizations</h3> <br />
                <Wrapper>
                  <DynamicTableStateless
                    head={head}
                    rows={rows}
                    isLoading={loadingMain}
                    rowsPerPage={10}
                    defaultPage={1}
                    defaultSortOrder="DESC"
                  />
                </Wrapper>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

Organization.propTypes = {
  getRecipients: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  manage: state.manage,
  organization: state.organization,
  servicedesk: state.servicedesk,
  jsd: state.jsd,
});

export default connect(mapStateToProps, {
  setLoadingMain,
  getDbOrganizations,
  removeDbOrganization,
  createDbOrganization,
  setOrganizationFormData,
  updateOrganization,
  setAlert,
  getOrganizations,
  getUsersPerOrg,
  getRecipients,
  getOrgUsers
})(Organization);
