import axios from 'axios';
import {
  POST_ERROR,
  GET_REQUEST_TYPES,
  SET_VIEW_FORM_DATA,
  SAVE_ORG_USERS,
  SET_LOADING,
} from './types';
import { setAlert } from './alert';

const _config = window._env_;

axios.defaults.withCredentials = true;

export const setLoading = (isLoading) => async (dispatch) => {
  dispatch({
    type: SET_LOADING,
    payload: isLoading,
  });
};

export const setViewFormData = (data) => async (dispatch) => {
  dispatch({
    type: SET_VIEW_FORM_DATA,
    payload: data,
  });
};

export const getRequestTypes = () => async (dispatch) => {
  try {
    const res = await axios.get(`${_config.MAT_URL}/requestTypes`);

    dispatch({
      type: GET_REQUEST_TYPES,
      payload: res.data,
    });
  } catch ({ response: { data, status } = {} }) {
    dispatch({
      type: POST_ERROR,
      payload: { msg: data, status },
    });
  }
};

export const getParticipantEmails = async (orgEmail) => {
  const params = {
    orgEmail,
  };
  try {
    return await axios.get(
      `${_config.MAT_URL}/api/1/customer/get-customer-by-org`,
      { params }
    );
  } catch (err) {
    console.log(err);
  }
};
// non redux function
export const getOrgUsers = async (id) => {
  try {
    let resultsArray = [];
    let lastPage = false;
    let start = 0;

    do {
      const res = await axios.get(`${_config.MAT_URL}/org-users`, {
        params: { start, id },
      });
      const { size, isLastPage, values } = res.data;
      lastPage = isLastPage;
      resultsArray.push(values);
      start = start + size;
    } while (!lastPage);

    const mergedResults = resultsArray.flat();
    return mergedResults;
  } catch (err) {
    console.log(err);
  }
};

export const saveOrgUsers = (payload) => async (dispatch) => {
  dispatch({
    type: SAVE_ORG_USERS,
    payload: payload,
  });
};
