import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  getUsers,
  setUserFormData,
  createUser,
  updateUser,
  removeUser
} from '../../actions/user';

import Button, { ButtonGroup } from '@atlaskit/button';
import Tooltip from '@atlaskit/tooltip';
import ArrowLeftCircleIcon from '@atlaskit/icon/glyph/arrow-left-circle';
import { setLoading as setLoadingMain } from '../../actions/servicedesk';
import TrashIcon from '@atlaskit/icon/glyph/trash';
import DynamicTableStateless from '@atlaskit/dynamic-table';
import styled from 'styled-components';
import UserAdd from './UserAdd.js';
import EditIcon from '@atlaskit/icon/glyph/edit';

const Wrapper = styled.div`
  min-width: 600px;
`;

const User = ({
  history,
  user: {
    addUserData,
    users,
    addUser,
    deleteUser,
    updateUser: updatedUser,
  },
  servicedesk: { loading: loadingMain },
  setLoadingMain,
  getUsers,
  createUser,
  updateUser,
  setUserFormData,
  removeUser
}) => {
  const [modaladd, setModalAdd] = useState(false);

  useEffect(() => {
    users.length && setLoadingMain(false);
  }, [getUsers, users, setLoadingMain]);

  useEffect(() => {
    getUsers();
  }, []);

  const toggleAdd = () => {
    setUserFormData('name', '');
    setUserFormData('role', '');
    setUserFormData('username', '');
    setUserFormData('_id', '');
    setUserFormData('admin', false);
    setUserFormData('siteAdmin', false);
    setModalAdd(!modaladd);
  };

  useEffect(() => {
    addUser && getUsers();
    deleteUser && getUsers();
    updatedUser && getUsers();
  }, [addUser, deleteUser, updatedUser]);

  const handleDelete = (id) => {
    removeUser(id);
  };

  const handleEdit = ({
    _id,
    name,
    admin,
    siteAdmin,
    username,
  }) => {
    setUserFormData('_id', _id);
    setUserFormData('name', name);
    setUserFormData('username', username);
    if (admin) {
      setUserFormData('role', 'Admin');
    } else if (siteAdmin) {
      setUserFormData('role', 'Site Admin');
    } else {
      setUserFormData('role', 'Standard');
    }
    setModalAdd(!modaladd);
  };

  const actionFormatter = (user) => {
    return (
      <div className="d-inline-flex align-items-center">
        <Tooltip content="Edit">
          <a
            id={`edit-${user._id}`}
            className="anchor-action-edit"
            href="#"
            onClick={(e) => {
              e.preventDefault();
              handleEdit(user);
            }}>
            <EditIcon size="medium" />
          </a>
        </Tooltip>
        <Tooltip content="Delete">
          <a
            id={`delete-${user._id}`}
            className="anchor-action-delete"
            href="#"
            onClick={(e) => {
              e.preventDefault();
              handleDelete(user);
            }}>
            <TrashIcon size="medium" />
          </a>
        </Tooltip>
      </div>
    );
  };

  const createHead = (withWidth) => {
    return {
      cells: [
        {
          key: 'name',
          content: 'Name',
          isSortable: true,
          width: withWidth ? 40 : undefined,
        },
        {
          key: 'username',
          content: 'Username',
          isSortable: true,
          width: withWidth ? 40 : undefined,
        },
        {
          key: 'role',
          content: 'Role',
          isSortable: true,
          width: withWidth ? 40 : undefined,
        },
        {
          key: '_id',
          content: 'Action',
          width: withWidth ? 40 : undefined,
        },
      ],
    };
  };
  const head = createHead(true);

  const getRole = (user) => {
    let role = 'Standard';
    if (user.admin) {
      role = 'Admin';
    } else if (user.siteAdmin) {
      role = 'Site Admin';
    }
    return role;
  }

  const rows = users.map((user) => ({
    key: `row-${user._id}`,
    cells: [
      {
        key: user.name,
        content: user.name || 'Assign a name',
      },
      {
        key: user.username,
        content: user.username,
      },
      {
        key: user.role,
        content: getRole(user),
      },
      {
        content: actionFormatter(user),
      },
    ]
  }));

  const handleAddUser = () => {
    if (addUserData.role === 'Admin') {
      addUserData.admin = true;
    } else if (addUserData.role === 'Site Admin') {
      addUserData.siteAdmin = true;
    }
    addUserData.name = addUserData.name.trim();
    addUserData.username = addUserData.username.trim();
    if (addUserData._id) {
      updateUser(addUserData);
    } else {
      createUser(addUserData);
    }
    toggleAdd();
  };

  return (
    <Fragment>
      <div className="container-fluid main-bg min-vh-100">
        <div className="container-fluid py-3">
          <div className="container upload-panel inner-bg">
            <div className="m-4">
              <div className="row">
                <div className="col-md-2">
                  <Tooltip>
                    <Button
                      iconBefore={
                        <ArrowLeftCircleIcon
                          size="small"
                          label="Go back to dashboard"
                        />
                      }
                      onClick={() => history.push('/dashboard')}>
                      BACK
                    </Button>
                  </Tooltip>
                </div>
                <div className="col-md-6"></div>
                <div className="col-md-4">
                  <div className="d-flex justify-content-end">
                    <ButtonGroup>
                      <Button appearance='primary' onClick={toggleAdd}>Add User</Button>
                    </ButtonGroup>
                    <UserAdd
                      modaladd={modaladd}
                      toggleAdd={toggleAdd}
                      addUser={handleAddUser}
                      updateUser={toggleAdd}
                    />
                  </div>
                </div>
              </div>
              <div className="mt-3">
                <h3>Users</h3> <br />
                <Wrapper>
                  <DynamicTableStateless
                    head={head}
                    rows={rows}
                    isLoading={loadingMain}
                    rowsPerPage={10}
                    defaultPage={1}
                    isFixedSize
                    defaultSortOrder="DESC"
                  />
                </Wrapper>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

User.propTypes = {
  getUsers: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  user: state.user,
  servicedesk: state.servicedesk,
});

export default connect(mapStateToProps, {
  setLoadingMain,
  setUserFormData,
  getUsers,
  createUser,
  updateUser,
  removeUser
})(User);
