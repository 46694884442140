import React, { useEffect, Fragment } from 'react';
import { connect } from 'react-redux';
import { setFormData, showMarkedValues } from '../../actions/issue';
import { getQueueIssues, createCustomerRequest } from '../../actions/jsd';
import {
  createProvider,
  getDbOrganizations,
  getProviders,
} from '../../actions/manage';
import { DateTimePicker } from '@atlaskit/datetime-picker';
import { formatISO, parseISO, isAfter } from 'date-fns';
import Form, { ErrorMessage, Field } from '@atlaskit/form';
import TextField from '@atlaskit/textfield';
import TextArea from '@atlaskit/textarea';
import ModalDialog, { ModalTransition } from '@atlaskit/modal-dialog';

import Select, { CreatableSelect } from '@atlaskit/select';
import styled from 'styled-components';
import { optionTimes } from '../../constants';

const WrapperMedium = styled.div`
  max-width: 316px;
`;
const WrapperLarge = styled.div`
  max-width: 492px;
`;

const MaintenanceForm = ({
  issue,
  createCustomerRequest,
  createProvider,
  getProviders,
  modal,
  toggleModal,
  formType,
  manage: {
    recipients,
    providers,
    loadingSelect,
    provider: newProvider,
    jsdMappings,
    dbOrganizations,
  },
  setFormData,
  getDbOrganizations,
}) => {
  const {
    params: {
      provider,
      assignee,
      productRef,
      providerTicketNo,
      startDate,
      endDate,
      backupStartDate,
      backupEndDate,
      impact,
      purpose,
      location,
      devices,
      backupDevices,
      outage,
      organizations,
    },
  } = issue;

  useEffect(() => {
    getDbOrganizations();
  }, []);

  useEffect(() => {
    newProvider && getProviders();
    newProvider && setFormData('provider', newProvider.name);
  }, [newProvider, getProviders]);

  const handleCreateProvider = (inputValue) => {
    createProvider({ name: inputValue.trim() });
  };

  const handleCreateIssue = () => {
    let customerIds;
    organizations
      .map(({ value }) => {
        return value;
      })
      .forEach((org) => {
        customerIds = recipients
          .filter(({ organisationId, notifMaintenance, customerId }) => {
            return notifMaintenance && customerId && organisationId === org;
          })
          .map(({ customerId }) => {
            return customerId;
          });
        issue.params.productRef = issue.params.productRef?.trim();
        issue.params.impact = issue.params.impact?.trim();
        issue.params.purpose = issue.params.purpose?.trim();
        issue.params.location = issue.params.location?.trim();
        issue.params.outage = issue.params.outage?.trim();
        issue.params.providerTicketNo = issue.params.providerTicketNo?.trim();
        issue.params.devices = issue.params.devices?.trim();
        issue.params.backupDevices = issue.params.backupDevices?.trim();
        issue.params.assignee = issue.params.assignee?.trim();
        createCustomerRequest(issue, formType, customerIds, org, jsdMappings);
      });

    toggleModal();
  };

  // const providers = config.PROVIDERS;
  const optionsProvider = providers.map(({ name }) => {
    return {
      label: name,
      value: name,
    };
  });

  const optionsOrg = dbOrganizations
    ? dbOrganizations.filter(org => !!org.id).map(({ name, id }) => {
        return {
          label: name,
          value: id,
        };
      })
    : [];
  return (
    <div>
      <ModalTransition>
        {modal && (
          <ModalDialog
            heading={`Create a new ${formType} maintenance announcement`}
            onClose={toggleModal}
            width="large"
            actions={[
              { text: 'Create', type: 'submit', form: 'form-create-ticket' },
              { text: 'Cancel', onClick: toggleModal },
            ]}>
            <Form onSubmit={handleCreateIssue}>
              {({ formProps }) => (
                <form id="form-create-ticket" {...formProps}>
                  <WrapperLarge>
                    <Field
                      name="provider"
                      label="Select or create a provider"
                      isRequired
                      value={{ label: provider, value: provider }}
                      validate={(value) => {
                        if (value) {
                          setFormData('provider', value.label);
                          return undefined;
                        }

                        return 'Please select a provider';
                      }}>
                      {({ fieldProps: { id, ...rest }, error }) => (
                        <Fragment>
                          <CreatableSelect
                            inputId="creatable-select-provider"
                            isDisabled={loadingSelect}
                            isLoading={loadingSelect}
                            {...rest}
                            validationState={error ? 'error' : 'default'}
                            placeholder="Select..."
                            isClearable
                            onCreateOption={handleCreateProvider}
                            options={optionsProvider}
                          />
                          {error && <ErrorMessage>{error}</ErrorMessage>}
                        </Fragment>
                      )}
                    </Field>
                    <Field
                      name="assignee"
                      label="Assignee"
                      defaultValue=""
                      isRequired>
                      {({ fieldProps, error, valid }) => (
                        <Fragment>
                          <TextField
                            {...fieldProps}
                            width="large"
                            value={assignee}
                            onChange={(newValue) => {
                              return (
                                newValue &&
                                setFormData(
                                  'assignee',
                                  newValue.target.value
                                )
                              );
                            }}
                          />
                        </Fragment>
                      )}
                    </Field>
                  </WrapperLarge>
                  <WrapperLarge>
                    <Field
                      name="productRef"
                      label="Product Reference"
                      defaultValue=""
                      isRequired>
                      {({ fieldProps, error, valid }) => (
                        <Fragment>
                          <TextField
                            {...fieldProps}
                            width="x-large"
                            value={productRef}
                            onChange={(newValue) => {
                              return (
                                newValue &&
                                setFormData('productRef', newValue.target.value)
                              );
                            }}
                          />
                        </Fragment>
                      )}
                    </Field>
                  </WrapperLarge>
                  <Field
                    name="providerTicketNo"
                    label="Provider Ticket Number"
                    defaultValue=""
                    isRequired>
                    {({ fieldProps, error, valid }) => (
                      <Fragment>
                        <TextField
                          {...fieldProps}
                          width="large"
                          value={providerTicketNo}
                          onChange={(newValue) => {
                            return (
                              newValue &&
                              setFormData(
                                'providerTicketNo',
                                newValue.target.value
                              )
                            );
                          }}
                        />
                      </Fragment>
                    )}
                  </Field>

                  <WrapperLarge>
                    <Field
                      name="startDateUtc"
                      label="Start date UTC"
                      defaultValue=""
                      isRequired
                      value={formatISO(startDate)}
                      defaultValue={formatISO(startDate)}
                      validate={(value) => {
                        if (value) {
                          setFormData('startDate', parseISO(value));
                          return undefined;
                        }

                        return 'Please select a start date';
                      }}>
                      {({ fieldProps: { id, ...rest }, error }) => (
                        <Fragment>
                          <DateTimePicker
                            {...rest}
                            validationState={error ? 'error' : 'none'}
                            dateFormat="DD/MM/YYYY"
                            timeIsEditable
                            datePickerProps={{ placeholder: 'DD/MM/YYYY' }}
                            timePickerProps={{ placeholder: 'h:mm a' }}
                            times={optionTimes}
                          />
                          {error && <ErrorMessage>{error}</ErrorMessage>}
                        </Fragment>
                      )}
                    </Field>
                  </WrapperLarge>
                  <WrapperLarge>
                    <Field
                      name="endDateUtc"
                      label="End date UTC"
                      isRequired
                      defaultValue={formatISO(endDate)}
                      validate={(value) => {
                        if (value) {
                          if (!isAfter(parseISO(value), startDate)) {
                            return 'End date should be later than Start date';
                          }
                          setFormData('endDate', parseISO(value));
                          return undefined;
                        }

                        return 'Please select an end date';
                      }}>
                      {({ fieldProps: { id, ...rest }, error }) => (
                        <Fragment>
                          <DateTimePicker
                            {...rest}
                            validationState={error ? 'error' : 'none'}
                            dateFormat="DD/MM/YYYY"
                            timeIsEditable
                            datePickerProps={{ placeholder: 'DD/MM/YYYY' }}
                            timePickerProps={{ placeholder: 'h:mm a' }}
                            times={optionTimes}
                          />
                          {error && <ErrorMessage>{error}</ErrorMessage>}
                        </Fragment>
                      )}
                    </Field>
                  </WrapperLarge>
                  <WrapperLarge>
                    <Field
                      name="backupStartDateUtc"
                      label="Backup start date UTC">
                      {({ fieldProps: { id, ...rest }, error }) => (
                        <Fragment>
                          <DateTimePicker
                            {...rest}
                            validationState={error ? 'error' : 'none'}
                            dateFormat="DD/MM/YYYY"
                            timeIsEditable
                            datePickerProps={{ placeholder: 'DD/MM/YYYY' }}
                            timePickerProps={{ placeholder: 'h:mm a' }}
                            times={optionTimes}
                            value={backupStartDate}
                            onChange={(newValue) => {
                              setFormData(
                                'backupStartDate',
                                newValue ? parseISO(newValue) : null
                              );
                            }}
                          />
                          {error && <ErrorMessage>{error}</ErrorMessage>}
                        </Fragment>
                      )}
                    </Field>
                  </WrapperLarge>
                  <WrapperLarge>
                    <Field name="backupEndDateUtc" label="Backup end date UTC">
                      {({ fieldProps: { id, ...rest }, error }) => (
                        <Fragment>
                          <DateTimePicker
                            {...rest}
                            validationState={error ? 'error' : 'none'}
                            dateFormat="DD/MM/YYYY"
                            timeIsEditable
                            datePickerProps={{ placeholder: 'DD/MM/YYYY' }}
                            timePickerProps={{ placeholder: 'h:mm a' }}
                            times={optionTimes}
                            value={backupEndDate}
                            onChange={(newValue) => {
                              setFormData(
                                'backupEndDate',
                                newValue ? parseISO(newValue) : null
                              );
                            }}
                          />
                          {error && <ErrorMessage>{error}</ErrorMessage>}
                        </Fragment>
                      )}
                    </Field>
                  </WrapperLarge>
                  <WrapperLarge>
                    <Field
                      name="organizations"
                      label="Organization"
                      isRequired={false}
                      value={organizations}
                      validate={(value) => {
                        if (Array.isArray(value) && value.length) {
                          setFormData('organizations', value);
                          return undefined;
                        }

                        return 'Please select organizations';
                      }}>
                      {({ fieldProps: { id, ...rest }, error }) => (
                        <Fragment>
                          <Select
                            inputId="select-organizations"
                            {...rest}
                            validationState={error ? 'error' : 'default'}
                            placeholder="Select..."
                            isMulti
                            isClearable
                            options={optionsOrg}
                          />
                          {error && <ErrorMessage>{error}</ErrorMessage>}
                        </Fragment>
                      )}
                    </Field>
                  </WrapperLarge>
                  <Field label="Impact" name="impact">
                    {({ fieldProps }) => (
                      <Fragment>
                        <TextArea
                          {...fieldProps}
                          resize="auto"
                          value={impact}
                          onChange={(newValue) => {
                            return (
                              newValue &&
                              setFormData('impact', newValue.target.value)
                            );
                          }}
                        />
                      </Fragment>
                    )}
                  </Field>
                  <Field
                    name="purpose"
                    label="Purpose"
                    defaultValue=""
                    isRequired>
                    {({ fieldProps, error, valid }) => (
                      <Fragment>
                        <TextField
                          {...fieldProps}
                          width="x-large"
                          value={purpose}
                          onChange={(newValue) => {
                            return (
                              newValue &&
                              setFormData('purpose', newValue.target.value)
                            );
                          }}
                        />
                      </Fragment>
                    )}
                  </Field>
                  <Field
                    name="location"
                    label="location"
                    defaultValue=""
                    isRequired>
                    {({ fieldProps, error, valid }) => (
                      <Fragment>
                        <TextField
                          {...fieldProps}
                          width="large"
                          value={location}
                          onChange={(newValue) => {
                            return (
                              newValue &&
                              setFormData('location', newValue.target.value)
                            );
                          }}
                        />
                      </Fragment>
                    )}
                  </Field>
                  <Field
                    name="devices"
                    label="Device(s)"
                    defaultValue=""
                    isRequired>
                    {({ fieldProps, error, valid }) => (
                      <Fragment>
                        <TextField
                          {...fieldProps}
                          width="large"
                          value={devices}
                          onChange={(newValue) => {
                            return (
                              newValue &&
                              setFormData('devices', newValue.target.value)
                            );
                          }}
                        />
                      </Fragment>
                    )}
                  </Field>
                  <Field
                    name="backupDevices"
                    label="Backup Device(s)"
                    defaultValue=""
                    isRequired>
                    {({ fieldProps, error, valid }) => (
                      <Fragment>
                        <TextField
                          {...fieldProps}
                          width="large"
                          value={backupDevices}
                          onChange={(newValue) => {
                            return (
                              newValue &&
                              setFormData('backupDevices', newValue.target.value)
                            );
                          }}
                        />
                      </Fragment>
                    )}
                  </Field>
                  <Field
                    name="outage"
                    label="outage"
                    defaultValue=""
                    isRequired>
                    {({ fieldProps, error, valid }) => (
                      <Fragment>
                        <TextField
                          {...fieldProps}
                          width="large"
                          value={outage}
                          onChange={(newValue) => {
                            return (
                              newValue &&
                              setFormData('outage', newValue.target.value)
                            );
                          }}
                        />
                      </Fragment>
                    )}
                  </Field>
                </form>
              )}
            </Form>
          </ModalDialog>
        )}
      </ModalTransition>
    </div>
  );
};

const mapStateToProps = (state) => ({
  issue: state.issue,
  jira: state.jira,
  servicedesk: state.servicedesk,
  manage: state.manage,
});

export default connect(mapStateToProps, {
  createCustomerRequest,
  getQueueIssues,
  setFormData,
  showMarkedValues,
  createProvider,
  getProviders,
  getDbOrganizations,
})(MaintenanceForm);
