import {
  SET_LOADING_SELECT,
  ADD_ORGANIZATION,
  UPDATE_ORGANIZATION,
  DELETE_ORGANIZATION,
  GET_ORGANIZATIONS,
  SET_ORGANIZATION_FORM_DATA,
  CREATE_DB_ORGANIZATION_RESPONSE,
} from '../actions/types';

const initialState = {
  loadingSelect: false,
  organizations: [],
  addOrganizationData: {
    name: '',
  },
  deleteOrganization: false,
  updateOrganization: false,
  addOrganization: false,
  organizations: [],
};

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case SET_LOADING_SELECT:
      return {
        ...state,
        loadingSelect: payload,
      };
    case SET_ORGANIZATION_FORM_DATA:
      const addNewData = { ...state.addOrganizationData };
      addNewData[payload.field] = payload.value;
      return {
        ...state,
        addOrganizationData: addNewData,
      };
    case GET_ORGANIZATIONS:
      return {
        ...state,
        organizations: payload,
      };
    case DELETE_ORGANIZATION:
      return {
        ...state,
        deleteOrganization: payload,
      };
    case UPDATE_ORGANIZATION:
      return {
        ...state,
        updateOrganization: payload,
      };
    case CREATE_DB_ORGANIZATION_RESPONSE:
      return {
        ...state,
        addOrganization: payload,
        loading: false,
      };
    default:
      return state;
  }
}
