import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  getRecipients,
  setLoadingButton,
  updateCustomerByName,
  updateCustomersByCustomerId,
  getUsersPerOrg,
  setEditData,
  setRecipientFormData,
  removeRecipient,
  updateUser,
  addRecipient,
  updateNotif,
  updateDbOrganisation,
  setSearchInput,
  getOrgUsers
} from '../../actions/manage';

import mongoose from 'mongoose';
import * as moment from 'moment';

import Button, { ButtonGroup, LoadingButton } from '@atlaskit/button';
import Tooltip from '@atlaskit/tooltip';
import Toggle from '@atlaskit/toggle';
import { orgFormatter, nameFormatter } from '../../utils';
import ArrowLeftCircleIcon from '@atlaskit/icon/glyph/arrow-left-circle';
import EditorSearchIcon from '@atlaskit/icon/glyph/editor/search';
import { showMarkedValues } from '../../actions/issue';
import { setLoading as setLoadingMain } from '../../actions/servicedesk';

import { getOrganizations } from '../../actions/jsd';

import { setAlert } from '../../actions/alert';
import Edit from './Edit';
import Add from './Add';
import DownloadIcon from '@atlaskit/icon/glyph/download';
import EditIcon from '@atlaskit/icon/glyph/edit';
import TrashIcon from '@atlaskit/icon/glyph/trash';
import DynamicTableStateless from '@atlaskit/dynamic-table';
import styled from 'styled-components';
import Textfield from '@atlaskit/textfield';
import { N50 } from '@atlaskit/theme/colors';
import Select from '@atlaskit/select';
import CsvDownloadButton from 'react-json-to-csv'

const Wrapper = styled.div`
  min-width: 600px;
`;

const Manage = ({
  history,
  manage: {
    recipients,
    loading,
    jsdCustomers,
    editData,
    addData,
    clearData,
    updatedUser,
    addUser,
    deleteUser,
    searchInput,
  },
  servicedesk: { loading: loadingMain },
  jsd: { organizations },
  getRecipients,
  setAlert,
  setLoadingButton,
  setLoadingMain,
  getUsersPerOrg,
  setEditData,
  getOrganizations,
  updateUser,
  removeRecipient,
  addRecipient,
  updateNotif,
  setRecipientFormData,
  setSearchInput,
}) => {
  useEffect(() => {
    !recipients.length && getRecipients();
    !organizations.length && getOrganizations();
    !jsdCustomers.length && getUsersPerOrg();
    jsdCustomers.length && setLoadingMain(false);
  }, [recipients, getUsersPerOrg, getRecipients, setLoadingMain]);

  useEffect(() => {
    updatedUser && getRecipients();
  }, [updatedUser]);

  useEffect(() => {
    addUser && getRecipients();
    deleteUser && getRecipients();
  }, [addUser, deleteUser]);

  const [modal, setModal] = useState(false);
  const [modaladd, setModalAdd] = useState(false);
  const [isSync, setIsSync] = useState(true);
  const [filterOption, setFilterOption] = useState({ label: 'All', value: 0 });

  const toggleEdit = () => setModal(!modal);

  const toggleAdd = () => {
    setRecipientFormData('name', '');
    setRecipientFormData('organisation', '');
    setRecipientFormData('notifIncident', false);
    setRecipientFormData('notifChange', false);
    setRecipientFormData('notifMaintenance', false);
    setModalAdd(!modaladd);
  };

  const handleSyncByName = async () => {
    setLoadingButton(true);
    const customersPromises = [];
    const orgPromises = [];
    for (const organization of organizations) {
      const { id, name, _links } = organization
      const customers = await getOrgUsers(+id) || [];
      customers.forEach(customer => {
        const { accountId, displayName, _links, emailAddress: email } = customer;
        customersPromises.push(
          new Promise((resolve) => {
            updateCustomersByCustomerId({
              customerId: accountId,
              name: displayName,
              email,
              avatarUrls: _links?.avatarUrls,
              organization: name,
              organizationId: +id
            }).then((res) => {
              resolve(res);
            });
          })
        );
      });
      orgPromises.push(
        new Promise((resolve) => {
          updateDbOrganisation({ id, name, _links, customerCount: customers.length }).then((res) => resolve(res));
        })
      )
    }
    const orgResult = await Promise.all(orgPromises);
    const customerResult = await Promise.all(customersPromises);
    setAlert({
      msg: `${customerResult.length} recipient(s), ${orgResult.length} organization(s) updated`,
      alertType: 'success',
      description:
        'Records has been found from JIRA service desk and will be synced locally.',
    });
    setLoadingButton(false);
    getRecipients();
  };
  // !customers ? setLoading(true) : setLoading(false);

  const handleEdit = ({
    _id,
    name,
    organisationId,
    organisation,
    notifIncident,
    notifChange,
    notifMaintenance,
  }) => {
    setEditData('_id', _id);
    setEditData('name', name);
    setEditData('organisationId', organisationId);
    setEditData('organisation', organisation);
    setEditData('notifIncident', notifIncident);
    setEditData('notifMaintenance', notifMaintenance);
    setEditData('notifChange', notifChange);
    toggleEdit();
  };

  const handleSync = async ({ name, organisation }) => {
    const jsdCustomer = jsdCustomers.find(({ displayName }) => {
      return displayName === name;
    });

    // retrieve organisationId
    const organization = organizations.find(({ name }) => {
      return name === organisation;
    });

    const organisationId = organization && organization.id;

    if (jsdCustomer) {
      const { accountId, displayName, avatarUrls, emailAddress: email } = jsdCustomer;
      const res = await updateCustomerByName({
        customerId: accountId,
        name: displayName,
        email,
        organisationId,
        organisation,
        avatarUrls,
      });
      setAlert({
        msg: `User ${res.data.name} updated`,
        alertType: 'success',
        description:
          'Record has been found from JIRA service desk and will be synced locally.',
      });
    } else {
      await updateCustomerByName({
        customerId: null,
        name,
        organisationId: null,
        organisation,
        avatarUrls: null,
      });
      setAlert({
        msg: `User ${name} not found`,
        alertType: 'warning',
        description: 'Record does not exist on JIRA service desk',
      });
    }
    getRecipients();
  };

  const handleDelete = (id) => {
    removeRecipient({ _id: id });
  };

  const handleUpdateUser = () => {
    updateUser(editData);
    toggleEdit();
  };

  const handleAddUser = () => {
    addRecipient(addData);
    toggleAdd();
  };

  const actionFormatter = (recipient) => {
    return (
      <div className="d-inline-flex align-items-center">
        <Tooltip content="Edit">
          <a
            id={`edit-${recipient._id}`}
            className="anchor-action-edit"
            href="#foo"
            onClick={(e) => {
              e.preventDefault();
              handleEdit(recipient);
            }}>
            <EditIcon size="medium" />
          </a>
        </Tooltip>
        <Tooltip content="Sync from JIRA">
          <a
            id={`sync-${recipient._id}`}
            className="anchor-action-edit"
            href="#foo"
            onClick={(e) => {
              e.preventDefault();
              handleSync(recipient);
            }}>
            <DownloadIcon size="medium" />
          </a>
        </Tooltip>
        <Tooltip content="Delete">
          <a
            id={`delete-${recipient._id}`}
            className="anchor-action-delete"
            href="#foo"
            onClick={(e) => {
              e.preventDefault();
              handleDelete(recipient);
            }}>
            <TrashIcon size="medium" />
          </a>
        </Tooltip>
      </div>
    );
  };

  const notifIncidentFormatter = (id, notifIncident) => {
    return (
      <Toggle
        id={id}
        isChecked={notifIncident}
        onChange={(newValue) => {
          updateNotif({
            id,
            type: 'notifIncident',
            value: newValue.target.checked,
          });
        }}
      />
    );
  };
  const notifChangeFormatter = (id, notifChange) => {
    return (
      <Toggle
        id={id}
        isChecked={notifChange}
        onChange={(newValue) => {
          updateNotif({
            id,
            type: 'notifChange',
            value: newValue.target.checked,
          });
        }}
      />
    );
  };

  const notifMaintenanceFormatter = (id, notifMaintenance) => {
    return (
      <Toggle
        id={id}
        isChecked={notifMaintenance}
        onChange={(newValue) => {
          updateNotif({
            id,
            type: 'notifMaintenance',
            value: newValue.target.checked,
          });
        }}
      />
    );
  };

  const getFilteredRecipients = (search) => {
    let customers = [];
    searchInput !== ''
      ? customers = recipients.filter(
          ({ name, organisation }) =>
            (name && name.toLowerCase().match(searchInput.toLowerCase())) ||
            (organisation &&
              organisation.toLowerCase().match(searchInput.toLowerCase()))
        )
      : customers = recipients;
    if (!filterOption) {
      return customers;
    }
    if (filterOption.value === 1) {
      customers = customers.filter(({ organisationId }) => !!organisationId);
    } else if (filterOption.value === 2) {
      customers = customers.filter(({ organisationId }) => !organisationId);
    }
    return customers;
  }

  let filteredRecipients = getFilteredRecipients();

  useEffect(() => {
    if (!filterOption) {
      return;
    }
    if (filterOption.value === 1) {
      filteredRecipients = []
    } else if (filterOption.value === 2) {

    }
  }, [filterOption, filteredRecipients]);

  const createHead = (withWidth) => {
    return {
      cells: [
        {
          key: 'name',
          content: 'Name',
          isSortable: true,
          width: withWidth ? 70 : undefined,
        },
        {
          key: 'organization',
          content: 'Organization',
          width: withWidth ? 50 : undefined,
        },
        {
          key: 'createdDate',
          content: 'Created Date',
          width: withWidth ? 50 : undefined,
        },
        {
          key: 'updatedDate',
          content: 'Last Modified Date',
          width: withWidth ? 50 : undefined,
        },
        {
          key: 'notifIncident',
          content: 'notif-incident',
          width: withWidth ? 20 : undefined,
        },
        {
          key: 'notifChange',
          content: 'notif-change',
          width: withWidth ? 20 : undefined,
        },
        {
          key: 'notifMaintenance',
          content: 'notif-maintenance',
          width: withWidth ? 25 : undefined,
        },
        {
          key: '_id',
          content: 'Action',
          width: withWidth ? 40 : undefined,
        },
      ],
    };
  };
  const head = createHead(true);

  const rows = filteredRecipients.map((recipient) => ({
    key: `row-${recipient._id}`,
    cells: [
      {
        key: recipient.name,
        content: nameFormatter(
          recipient.name,
          recipient.customerId,
          recipient.avatarUrls
        ),
      },
      {
        content: orgFormatter(recipient.organisation, recipient.organisationId),
      },
      {
        content: moment(new mongoose.Types.ObjectId(recipient._id).getTimestamp()).format('DD MMM h:MM A'),
      },
      {
        content: moment(recipient.dateModified).format('DD MMM h:MM A'),
      },
      {
        content: notifIncidentFormatter(recipient._id, recipient.notifIncident),
      },
      {
        content: notifChangeFormatter(recipient._id, recipient.notifChange),
      },
      {
        content: notifMaintenanceFormatter(
          recipient._id,
          recipient.notifMaintenance
        ),
      },
      {
        content: actionFormatter(recipient),
      },
    ],
  }));

  return (
    <Fragment>
      <div className="container-fluid main-bg min-vh-100">
        <div className="container-fluid py-3">
          <div className="container upload-panel inner-bg">
            <div className="m-4">
              <div className="row">
                <div className="col-md-2">
                  <Tooltip>
                    <Button
                      iconBefore={
                        <ArrowLeftCircleIcon
                          size="small"
                          label="Go back to dashboard"
                        />
                      }
                      onClick={() => history.push('/dashboard')}>
                      BACK
                    </Button>
                  </Tooltip>
                </div>
                <div className="col-md-6"></div>
                <div className="col-md-4">
                  <div className="d-flex justify-content-end">
                    <ButtonGroup>
                      <LoadingButton
                        appearance="primary"
                        isLoading={loading}
                        onClick={handleSyncByName}>
                        Sync from Jira
                      </LoadingButton>
                      &nbsp;
                      <Button onClick={toggleAdd}>Add Recipient</Button>{' '}
                    </ButtonGroup>
                    <Add
                      modaladd={modaladd}
                      toggleAdd={toggleAdd}
                      addRecipient={handleAddUser}
                    />
                  </div>
                </div>
              </div>
              <div className="mt-3">
                <h3>Recipients</h3> <br />
                <Tooltip content="Search name or organization">
                    <div style={{ display: 'flex', marginBottom: 20 }}>
                      <Textfield
                        id="search-recipient"
                        placeholder="Search"
                        isCompact
                        width="medium"
                        height="500px"
                        elemBeforeInput={
                          <EditorSearchIcon size="xsmall" primaryColor={N50} />
                        }
                        onChange={(newValue) => {
                          return newValue && setSearchInput(newValue.target.value);
                        }}
                      />
                      <div style={{ width: 200, marginLeft: 10, marginRight: 10 }}>
                        <Select
                          placeholder="Filter Options:"
                          value={filterOption}
                          onChange={(value) => setFilterOption(value)}
                          appearance="default"
                          options={[
                            { label: 'All', value: 0 },
                            { label: 'Synced', value: 1 },
                            { label: 'Not Synced', value: 2 },
                          ]}
                        />
                      </div>
                      <CsvDownloadButton
                        filename="customers.csv"
                        style={{
                          background:"#0052CC",
                          backgroundColor:"#0052CC",
                          color: '#FFFFFF',
                          borderRadius: 3,
                          padding: '0 10px',
                          fontWeight: 500,
                          borderWidth: 0
                        }}
                        data={filteredRecipients.map(recipient => {
                          return {
                            Name: recipient.name,
                            Email: recipient.email,
                            Organization: recipient.organisation,
                            'Notif Incident': recipient.notifIncident ? 'YES' : 'NO',
                            'Notif Change': recipient.notifChange ? 'YES' : 'NO',
                            'Notif Maintenance': recipient.notifChange ? 'YES' : 'NO',
                          };
                        })}>Export</CsvDownloadButton>
                    </div>
                </Tooltip>
                <Wrapper>
                  <DynamicTableStateless
                    head={head}
                    rows={rows}
                    isLoading={loadingMain}
                    rowsPerPage={10}
                    defaultPage={1}
                    isFixedSize
                    defaultSortOrder="DESC"
                  />
                </Wrapper>
                <Edit
                  modal={modal}
                  toggleEdit={toggleEdit}
                  updateUser={handleUpdateUser}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

Manage.propTypes = {
  getRecipients: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  manage: state.manage,
  servicedesk: state.servicedesk,
  jsd: state.jsd,
});

export default connect(mapStateToProps, {
  showMarkedValues,
  getRecipients,
  setAlert,
  setLoadingMain,
  setLoadingButton,
  getUsersPerOrg,
  setEditData,
  removeRecipient,
  updateUser,
  addRecipient,
  updateNotif,
  getOrganizations,
  setRecipientFormData,
  setSearchInput,
  getOrgUsers
})(Manage);
