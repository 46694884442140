import {
  GET_SERVICE_DESKS,
  GET_CUSTOMER_REQUESTS,
  GET_CUSTOMERS,
  POST_ERROR,
  SET_REQUESTS,
  CREATE_CUSTOMER_REQUEST,
  SET_VIEW_FORM_DATA,
  GET_REQUEST_TYPES,
  GET_REQUEST_PARTICIPANT_IDS,
  GET_ORG_USERS,
  GET_ORGANIZATION_USERS,
  SAVE_ORG_USERS,
  GET_QUEUES,
  SET_LOADING,
} from '../actions/types';

const initialState = {
  info: null,
  logout: true,
  isAuthenticated: false,
  token: null,
  loading: true,
  serviceDesks: null,
  orgUsers: null,
  requests: [],
  requestTypes: null,
  viewFormData: {},
  reqParticipantIds: [],
  customers: null,
  masterList: [],
  queues: [],
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SET_LOADING:
      return {
        ...state,
        loading: payload,
      };

    case GET_CUSTOMERS:
      return {
        ...state,
        customers: payload,
        loading: false,
        // isAuthenticated: true
      };
    case GET_SERVICE_DESKS:
      return {
        ...state,
        serviceDesks: payload,
        // loading: false
      };
    case GET_CUSTOMER_REQUESTS:
      return {
        ...state,
        customerRequests: payload,
        loading: false,
      };
    case GET_ORGANIZATION_USERS:
      return {
        ...state,
        organizationUsers: payload,
        // loading: false
      };

    case SET_REQUESTS:
      return {
        ...state,
        requests: payload,
        // loading: false
      };
    case CREATE_CUSTOMER_REQUEST:
      return {
        ...state,
        request: payload,
        loading: false,
      };
    case GET_REQUEST_TYPES:
      return {
        ...state,
        requestTypes: payload,
        loading: false,
      };
    case SET_VIEW_FORM_DATA:
      return {
        ...state,
        viewFormData: payload,
      };
    case POST_ERROR:
      return {
        ...state,
        error: payload,
        loading: false,
        // token: null,
        // isAuthenticated: false
      };

    case GET_REQUEST_PARTICIPANT_IDS:
      return {
        ...state,
        reqParticipantIds: payload,
      };
    case GET_ORG_USERS:
      return {
        ...state,
        orgUsers: payload,
      };
    case SAVE_ORG_USERS:
      return {
        ...state,
        masterList: payload,
      };
    case GET_QUEUES:
      return {
        ...state,
        queues: payload,
      };

    default:
      return state;
  }
}
