import {
  SET_FORM_DATA,
  CLEAR_FORM_DATA,
  SHOW_MARKED_VALUES,
} from './types';

export const setFormData = (field, value) => dispatch => {
  dispatch({
    type: SET_FORM_DATA,
    payload: { field, value }
  });
};

export const clearFormData = () => dispatch => {
  dispatch({
    type: CLEAR_FORM_DATA,
  });
};

export const showMarkedValues = (showMark) => dispatch => {
  dispatch({
    type: SHOW_MARKED_VALUES,
    payload: showMark
  });
};
