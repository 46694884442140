import { v4 as uuidv4 } from 'uuid';
import { SET_ALERT, REMOVE_ALERT } from './types';

export const setAlert =
  ({ msg, alertType, description = null, actions = null }) =>
  (dispatch) => {
    // const id = uuidv4();
    dispatch({
      type: SET_ALERT,
      payload: { msg, alertType, description, actions },
    });
  };

export const removeAlert = () => (dispatch) => {
  dispatch({ type: REMOVE_ALERT });
};
