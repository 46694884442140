import {
  SET_LOADING_SELECT,
  SET_PROVIDER_FORM_DATA,
  GET_PROVIDERS,
  DELETE_PROVIDER,
  UPDATE_PROVIDER,
  CREATE_PROVIDER,
} from '../actions/types';

const initialState = {
  loadingSelect: false,
  providers: [],
  addProviderData: {
    name: '',
  },
  deleteProvider: false,
  updateProvider: false,
  addProvider: false,
};

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case SET_LOADING_SELECT:
      return {
        ...state,
        loadingSelect: payload,
      };
    case SET_PROVIDER_FORM_DATA:
      const addNewData = { ...state.addProviderData };
      addNewData[payload.field] = payload.value;
      return {
        ...state,
        addProviderData: addNewData,
      };
    case GET_PROVIDERS:
      return {
        ...state,
        providers: payload,
      };
    case DELETE_PROVIDER:
      return {
        ...state,
        deleteProvider: payload,
      };
    case UPDATE_PROVIDER:
      return {
        ...state,
        updateProvider: payload,
      };
    case CREATE_PROVIDER:
      return {
        ...state,
        addProvider: payload,
        loading: false,
      };
    default:
      return state;
  }
}
