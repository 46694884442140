import React, { Fragment } from 'react';
import { connect } from 'react-redux';

import { setUserFormData } from '../../actions/user';
import Form, {
  ErrorMessage,
  Field,
} from '@atlaskit/form';
import TextField from '@atlaskit/textfield';
import ModalDialog, { ModalTransition } from '@atlaskit/modal-dialog';
import Select from '@atlaskit/select';

const UserAdd = ({
  modaladd,
  toggleAdd,
  addUserData: {
    name,
    username,
    role
  },
  setUserFormData,
  addUser,
}) => {
  return (
    <div>
      <ModalTransition>
        {modaladd && (
          <ModalDialog
            heading="Add User"
            onClose={toggleAdd}
            actions={[
              { text: 'Submit', type: 'submit', form: 'form-add' },
              { text: 'Cancel', onClick: toggleAdd },
            ]}>
            <Form onSubmit={addUser}>
              {({ formProps }) => (
                <form id="form-add" {...formProps}>
                  <Field
                    name="role"
                    label="Select a role"
                    isRequired
                    validate={(value) => {
                      if (value) {
                        setUserFormData('role', value.label);
                        return undefined;
                      }
                      if (role) {
                        return;
                      }
                      return 'Please select a role';
                    }}
                    value={[]}
                  >
                    {({ fieldProps: { id, ...rest }, error }) => (
                      <Fragment>
                        <Select
                          inputId="select-roles"
                          {...rest}
                          value={{ label: role, value: role }}
                          options={[
                            { label: 'Admin', value: 'Admin' },
                            { label: 'Site Admin', value: 'Site Admin' },
                            { label: 'Standard', value: 'Standard' },
                          ]}
                        />
                        {error && <ErrorMessage>{error}</ErrorMessage>}
                      </Fragment>
                    )}
                  </Field>
                  <Field
                    name="name"
                    label="Name"
                    defaultValue=""
                    isRequired
                    value={name}>
                    {({ fieldProps, error, valid }) => (
                      <Fragment>
                        <TextField
                          {...fieldProps}
                          value={name}
                          onChange={(newValue) => {
                            return (
                              newValue &&
                              setUserFormData(
                                'name',
                                newValue.target.value
                              )
                            );
                          }}
                        />
                      </Fragment>
                    )}
                  </Field>
                  <Field
                    name="username"
                    label="Username"
                    defaultValue=""
                    isRequired
                    value={username}>
                    {({ fieldProps, error, valid }) => (
                      <Fragment>
                        <TextField
                          {...fieldProps}
                          value={username}
                          onChange={(newValue) => {
                            return (
                              newValue &&
                              setUserFormData(
                                'username',
                                newValue.target.value
                              )
                            );
                          }}
                        />
                      </Fragment>
                    )}
                  </Field>
                </form>
              )}
            </Form>
          </ModalDialog>
        )}
      </ModalTransition>
    </div>
  );
};

const mapStateToProps = (state) => (
  {
  addUserData: state.user.addUserData,
  // clearData: state.manage.clearData,
});

export default connect(mapStateToProps, {
  setUserFormData,
})(UserAdd);
