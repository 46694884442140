import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';

import Tooltip from '@atlaskit/tooltip';
import ArrowLeftCircleIcon from '@atlaskit/icon/glyph/arrow-left-circle';
import { setLoading as setLoadingMain } from '../../actions/servicedesk';
import TrashIcon from '@atlaskit/icon/glyph/trash';
import DynamicTableStateless from '@atlaskit/dynamic-table';
import styled from 'styled-components';
import EditIcon from '@atlaskit/icon/glyph/edit';
import Button, { ButtonGroup } from '@atlaskit/button';
import { setProviderFormData, getProviders, createProvider, removeProvider, updateProvider } from '../../actions/provider';
import ProviderAdd from './ProviderAdd';

const Wrapper = styled.div`
  min-width: 600px;
`;

const Provider = ({
  history,
  provider: {
    providers,
    deleteProvider,
    addProviderData,
    addProvider,
    updateProvider: updatedProvider
  },
  servicedesk: { loading: loadingMain },
  setLoadingMain,
  getProviders,
  setProviderFormData,
  createProvider,
  removeProvider,
  updateProvider,
}) => {
  const [modaladd, setModalAdd] = useState(false);

  useEffect(() => {
    providers.length && setLoadingMain(false);
  }, [getProviders, providers, setLoadingMain]);

  useEffect(() => {
    getProviders();
  }, []);
  
  useEffect(() => {
    addProvider && getProviders();
    deleteProvider && getProviders();
    updatedProvider && getProviders();
  }, [addProvider, deleteProvider, updatedProvider]);

  const handleDelete = (id) => {
    removeProvider(id);
  };

  const toggleAdd = () => {
    setProviderFormData('name', '');
    setModalAdd(!modaladd);
  };

  const handleEdit = ({
    _id,
    name,
  }) => {
    setProviderFormData('_id', _id);
    setProviderFormData('name', name);
    setModalAdd(!modaladd);
  };

  const actionFormatter = (provider) => {
    return (
      <div className="d-inline-flex align-items-center">
        <Tooltip content="Edit">
          <a
            id={`edit-${provider._id}`}
            className="anchor-action-edit"
            href="#"
            onClick={(e) => {
              e.preventDefault();
              handleEdit(provider);
            }}>
            <EditIcon size="medium" />
          </a>
        </Tooltip>
        <Tooltip content="Delete">
          <a
            id={`delete-${provider._id}`}
            className="anchor-action-delete"
            href="#"
            onClick={(e) => {
              e.preventDefault();
              handleDelete(provider);
            }}>
            <TrashIcon size="medium" />
          </a>
        </Tooltip>
      </div>
    );
  };

  const createHead = (withWidth) => {
    return {
      cells: [
        {
          key: 'name',
          content: 'Name',
          isSortable: true,
          width: withWidth ? 40 : undefined,
        },
        {
          key: '_id',
          content: 'Action',
          width: withWidth ? 40 : undefined,
        },
      ],
    };
  };
  const head = createHead(true);

  const rows = providers.map((provider) => ({
    key: `row-${provider._id}`,
    cells: [
      {
        key: provider.name,
        content: provider.name,
      },
      {
        content: actionFormatter(provider),
      },
    ]
  }));

  const handleAddProvider = () => {
    addProviderData.name = addProviderData.name.trim();
    if (addProviderData._id) {
      updateProvider(addProviderData);
    } else {
      createProvider(addProviderData);
    }
    toggleAdd();
  };

  return (
    <Fragment>
      <div className="container-fluid main-bg min-vh-100">
        <div className="container-fluid py-3">
          <div className="container upload-panel inner-bg">
            <div className="m-4">
              <div className="row">
                <div className="col-md-2">
                  <Tooltip>
                    <Button
                      iconBefore={
                        <ArrowLeftCircleIcon
                          size="small"
                          label="Go back to dashboard"
                        />
                      }
                      onClick={() => history.push('/dashboard')}>
                      BACK
                    </Button>
                  </Tooltip>
                </div>
                <div className="col-md-6"></div>
                <div className="col-md-4">
                  <div className="d-flex justify-content-end">
                    <ButtonGroup>
                      <Button onClick={toggleAdd}>Add Provider</Button>
                    </ButtonGroup>
                    <ProviderAdd
                      modaladd={modaladd}
                      toggleAdd={toggleAdd}
                      addProvider={handleAddProvider}
                    />
                  </div>
                </div>
              </div>
              <div className="mt-3">
                <h3>Providers</h3> <br />
                <Wrapper>
                  <DynamicTableStateless
                    head={head}
                    rows={rows}
                    isLoading={loadingMain}
                    rowsPerPage={10}
                    defaultPage={1}
                    isFixedSize
                    defaultSortOrder="DESC"
                  />
                </Wrapper>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  provider: state.provider,
  servicedesk: state.servicedesk,
});

export default connect(mapStateToProps, {
  setLoadingMain,
  setProviderFormData,
  getProviders,
  createProvider,
  removeProvider,
  updateProvider,
})(Provider);
