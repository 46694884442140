import React, { useEffect, Fragment, useState } from 'react';
import { connect } from 'react-redux';

import { setRecipientFormData } from '../../actions/manage';
import { createDbOrganization, getDbOrganizations } from '../../actions/manage';
import Form, {
  ErrorMessage,
  Field,
  Fieldset,
  CheckboxField,
  FormFooter,
  HelperMessage,
  ValidMessage,
} from '@atlaskit/form';
import TextField from '@atlaskit/textfield';
import ModalDialog, { ModalTransition } from '@atlaskit/modal-dialog';
import Button from '@atlaskit/button';
import { Checkbox } from '@atlaskit/checkbox';
import Select, { CreatableSelect } from '@atlaskit/select';

const Add = ({
  modaladd,
  toggleAdd,
  addData: {
    name,
    organisation,
    organisationInput,
    notifIncident,
    notifChange,
    notifMaintenance,
    loading,
  },
  setRecipientFormData,
  addRecipient,
  recipients,
  manage: { dbOrganizations, createDbOrgResp },
  createDbOrganization,
  getDbOrganizations,
}) => {
  useEffect(() => {
    getDbOrganizations();
  }, []);

  useEffect(() => {
    getDbOrganizations();
  }, [createDbOrgResp]);

  const colors = [
    { label: 'blue', value: 'blue' },
    { label: 'red', value: 'red' },
    { label: 'purple', value: 'purple' },
    { label: 'black', value: 'black' },
    { label: 'white', value: 'white' },
    { label: 'gray', value: 'gray' },
    { label: 'yellow', value: 'yellow' },
    { label: 'orange', value: 'orange' },
    { label: 'teal', value: 'teal' },
  ];

  const [isLoading, setLoading] = useState(false);

  const optionsOrg = dbOrganizations
    ? dbOrganizations.filter(org => !!org.id).map(({ name }) => {
        return {
          label: name,
          value: name,
        };
      })
    : [];

  const handleCreateDbOrganization = (inputValue) => {
    setLoading(true);
    createDbOrganization({ name: inputValue });
    setLoading(false);
  };

  return (
    <div>
      <ModalTransition>
        {modaladd && (
          <ModalDialog
            heading="Add Recipient"
            onClose={toggleAdd}
            actions={[
              { text: 'Submit', type: 'submit', form: 'form-add' },
              { text: 'Cancel', onClick: toggleAdd },
            ]}>
            <Form onSubmit={addRecipient}>
              {({ formProps }) => (
                <form id="form-add" {...formProps}>
                  <Field
                    name="name"
                    label="Name"
                    defaultValue=""
                    isRequired
                    value={name}>
                    {({ fieldProps, error, valid }) => (
                      <Fragment>
                        <TextField
                          {...fieldProps}
                          value={name}
                          onChange={(newValue) => {
                            return (
                              newValue &&
                              setRecipientFormData(
                                'name',
                                newValue.target.value
                              )
                            );
                          }}
                        />
                      </Fragment>
                    )}
                  </Field>
                  <Field
                    name="organisation"
                    label="Select or create an organization"
                    isRequired
                    value={{ label: organisation, value: organisation }}
                    validate={(value) => {
                      if (value) {
                        setRecipientFormData('organisation', value.label);
                        return undefined;
                      }

                      return 'Please select an organization';
                    }}>
                    {({ fieldProps: { id, ...rest }, error }) => (
                      <Fragment>
                        <CreatableSelect
                          inputId="creatable-select-org"
                          isDisabled={isLoading}
                          isLoading={isLoading}
                          {...rest}
                          validationState={error ? 'error' : 'default'}
                          placeholder="Select..."
                          isClearable
                          // defaultValue={optionsOrg[0]}
                          onCreateOption={handleCreateDbOrganization}
                          options={optionsOrg}

                          // value={{ label: 'Select...', value: 'select' }}
                        />
                        {error && <ErrorMessage>{error}</ErrorMessage>}
                      </Fragment>
                    )}
                  </Field>
                  <Fieldset legend="Notifications">
                    <CheckboxField name="notifIncident">
                      {({ fieldProps }) => (
                        <Checkbox
                          {...fieldProps}
                          label="notif-incident"
                          value={notifIncident}
                          onClick={(newValue) => {
                            setRecipientFormData(
                              'notifIncident',
                              newValue.target.checked
                            );
                          }}
                        />
                      )}
                    </CheckboxField>
                    <CheckboxField name="notifChange">
                      {({ fieldProps }) => (
                        <Checkbox
                          {...fieldProps}
                          label="notif-change"
                          value={notifChange}
                          onClick={(newValue) => {
                            setRecipientFormData(
                              'notifChange',
                              newValue.target.checked
                            );
                          }}
                        />
                      )}
                    </CheckboxField>
                    <CheckboxField name="notifMaintenance">
                      {({ fieldProps }) => (
                        <Checkbox
                          {...fieldProps}
                          label="notif-maintenance"
                          value={notifMaintenance}
                          onClick={(newValue) => {
                            setRecipientFormData(
                              'notifMaintenance',
                              newValue.target.checked
                            );
                          }}
                        />
                      )}
                    </CheckboxField>
                  </Fieldset>
                </form>
              )}
            </Form>
          </ModalDialog>
        )}
      </ModalTransition>
    </div>
  );
};

const mapStateToProps = (state) => ({
  addData: state.manage.addData,
  recipients: state.manage.recipients,
  clearData: state.manage.clearData,
  manage: state.manage,
});

// const mapDispatchToProps = {

// }

export default connect(mapStateToProps, {
  setRecipientFormData,
  createDbOrganization,
  getDbOrganizations,
})(Add);
