export const ERROR_CODE = {
  ok: { code: 0, msg: 'VALIDATED' },
  csvFormatError: { code: 1, msg: 'CSV_FILE_ERROR' },
  csvValueError: { code: 2, msg: 'CSV_VALUE_ERROR' },
};

const buildTimes = () => {
  let times = [],
    hr,
    min = 30,
    minMultiplier;
  for (hr = 0; hr < 24; hr++) {
    for (minMultiplier = 0; minMultiplier < 2; minMultiplier++) {
      times.push(hr + ':' + (minMultiplier === 0 ? '00' : min * minMultiplier));
    }
  }
  return times;
};

export const optionTimes = buildTimes();
