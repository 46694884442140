import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { AutoDismissFlag, FlagGroup } from '@atlaskit/flag';
import { G300 } from '@atlaskit/theme/colors';
import SuccessIcon from '@atlaskit/icon/glyph/check-circle';
import WarningIcon from '@atlaskit/icon/glyph/warning';
import { Y200 } from '@atlaskit/theme/colors';
import ErrorIcon from '@atlaskit/icon/glyph/error';
import { R400 } from '@atlaskit/theme/colors';
import InfoIcon from '@atlaskit/icon/glyph/info';
import { N500 } from '@atlaskit/theme/colors';
import { removeAlert } from '../../actions/alert';

const createIcon = (type) => {
  if (type === 'success') {
    return <SuccessIcon primaryColor={G300} label="Success" />;
  } else if (type === 'warning') {
    return <WarningIcon secondaryColor={Y200} label="Warning" />;
  } else if (type === 'error') {
    return <ErrorIcon label="Error" secondaryColor={R400} />;
  } else {
    return <InfoIcon label="Info" secondaryColor={N500} />;
  }
};

const Alert = ({ alerts, removeAlert }) => {
  const handleDismiss = () => {
    removeAlert();
  };
  return (
    // <div>
    //   <AutoDismissFlag
    //     icon={createIcon(alert.alertType)}
    //     id={alert.id}
    //     key={alert.id}
    //     title={alert.msg}
    //   />
    // </div>
    <div>
      <FlagGroup onDismissed={handleDismiss}>
        {alerts.map((alert) => {
          return alert.alertType === 'success' ? (
            <AutoDismissFlag
              id={alert.id}
              icon={createIcon(alert.alertType)}
              key={alert.id}
              title={alert.msg}
              description={alert.description}
              actions={alert.actions}
            />
          ) : (
            <AutoDismissFlag
              appearance={alert.alertType}
              id={alert.id}
              icon={createIcon(alert.alertType)}
              key={alert.id}
              title={alert.msg}
              description={alert.description}
              actions={alert.actions}
            />
          );
        })}
      </FlagGroup>
    </div>
  );
};

Alert.propTypes = {
  alerts: PropTypes.array.isRequired,
};

const mapStateToProps = (state) => ({
  alerts: state.alert,
});

export default connect(mapStateToProps, { removeAlert })(Alert);
